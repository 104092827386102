import * as React from 'react'
import { useParams } from 'react-router-dom'
import { defaults, StoreActionApi } from 'react-sweet-state'
import api, { ApiCallConfig } from './api'
import { getStoreState, saveStoreState } from './local-storage'

export interface ClearRequestPayload {
  key: string
}

export interface ApiCallOptions {
  progress?: boolean
  headers?: { [key: string]: any },
  data?: any
}

export interface ApiRequest {
  lastCallDate: number
  status: 'inProgress' | 'success' | 'error'
  loaded?: string
  message?: string
  response?: {
    message?: string
    [key: string]: any
  }
  error?: {
    message?: string
    code: number
    [key: string]: any
  }
  [key: string]: any
}

export interface ResourceState<Schema> {
  readonly endpoint: string
  all: Schema[]
  pagination?: {
    currentPage: number
    pageSize: number
    total: number
    totalPages: number
  }
  currentId?: string
  requests: {
    [key: string]: ApiRequest
  }
}

//Default Resource initial state
export const defaultResourceInitialState = {
  all: [],
  currentId: undefined,
  requests: {}
}

//Custom hooks

//Fetch resource (by url param) on mount & on location change
//Remove current when component is unmounted
export const useResourceFromParams = (actions: any, paramsKey = 'id'): void => {
  const locationParams: any = useParams()
  React.useEffect(() => {
    if (locationParams[paramsKey] && locationParams[paramsKey] !== 'new')
      actions.get(locationParams[paramsKey])
    else
      actions.setCurrent()
    return () => actions.setCurrent()
  }, [locationParams.id]) 
  
  React.useEffect(() => {
    if (actions.getSchema)
      actions.getSchema()
  }, [])
}


//Private actions
const privateActions = {
  //Add or replace resource in store (all[])
  //Option: set current
  addOrReplaceItemInList: (item: { _id: string, [key:string]: any }, setAsCurrent?: boolean) => 
    ({ setState, getState }: StoreActionApi<any>) => {
      const { all } = getState()
      const existingItemIndex = item._id && all.findIndex((doc: any) => doc._id === item._id)
      if (existingItemIndex >= 0)
        all[existingItemIndex] = item
      else
        all.push(item)
      const newState: any = { all: [...all] }
      if (setAsCurrent && item._id)
        newState.currentId = item._id
      setState(newState)
    }
}

//Generic actions
export const genericActions = {
  apiFetch: (query?: any, options?: ApiCallOptions) => async ({ setState, getState, dispatch }: StoreActionApi<any>) => {
    const res = await dispatch(genericActions.api({
      method: 'get',
      url: getState().endpoint,
      data: query,
    }, options))
    if (res && res.result)
      setState({ all: res.result })
    return res
  },
  apiList: (query?: any, options?: ApiCallOptions) => async ({ setState, getState, dispatch }: StoreActionApi<any>) => {
    const res = await dispatch(genericActions.api({
      method: 'get',
      url: `${getState().endpoint}/pages`,
      data: query,
    }, options))
    
    if (res && res.result)
      setState({ all: res.result.rows, pagination: {
        currentPage: res.result.page,
        pageSize: res.result.pageSize,
        total: res.result.total,
        totalPages: res.result.totalPages
      } })
    return res
  },
  apiGet: (_id: string, options?: ApiCallOptions) => async ({ getState, dispatch }: StoreActionApi<any>) => {
    const res = await dispatch(genericActions.api({
      method: 'get',
      url: `${getState().endpoint}/${_id}`,
      data: options && options.data
    }, options))
    if (res && res.result)
      dispatch(privateActions.addOrReplaceItemInList(res.result, true))
    return res
  },
  apiCreate: (data: any, options?: ApiCallOptions) => async ({ getState, dispatch }: StoreActionApi<any>) => {
    const res = await dispatch(genericActions.api({
      method: 'post',
      url: getState().endpoint,
      data
    }, options))
    if (res && res.result)
      dispatch(privateActions.addOrReplaceItemInList(res.result, true))
    return res
  },
  apiUpdate: (data: any, options?: ApiCallOptions) => async ({ getState, dispatch }: StoreActionApi<any>) => {
    const res = await dispatch(genericActions.api({
      method: 'put',
      url: `${getState().endpoint}/${data._id}`,
      data
    }, options))
    if (res && res.result)
      dispatch(privateActions.addOrReplaceItemInList(res.result))
    return res
  },
  apiCreateOrUpdate: (data: any, options?: ApiCallOptions) => async ({
    //@ts-ignore
    actions,
    getState,
    dispatch
  }: StoreActionApi<any>) => {
    if (getState().currentId)
      return await dispatch(actions.update(data, options))
    return await dispatch(actions.create(data, options))
  },
  apiDelete: (_id: string, options?: ApiCallOptions) => async ({ getState, dispatch }: StoreActionApi<any>) => {
    const res = await dispatch(genericActions.api({
      url: `${getState().endpoint}/${_id}`,
      method: 'delete'
    }, options))
    return res
  },
  setCurrent: (item?: { _id?: string, [key:string]: any }) => ({ setState }: StoreActionApi<any>) => {
    setState({ currentId: item && item._id })
  },
  getRequest: (method?: string, withId?: boolean) =>
    ({ getState }: StoreActionApi<any>) => {
      const { requests, endpoint, currentId } = getState()
      if (!method && currentId)
        method = 'put'
      else if (!method)
        method = 'post'
      if ((method === 'get' && !withId) || method === 'post')
        return requests[`${method} ${endpoint}`]
      return requests[`${method} ${endpoint}/${currentId}`]
    },
  clearRequest: (data: ClearRequestPayload) => async ({ setState, getState }: StoreActionApi<any>) => {
    const requests = getState().requests
    delete requests[data.key]
    setState({ requests })
  },
  clearRequests: () => ({ setState }: StoreActionApi<any>) => {
    setState({ requests: {} })
  },
  api: (config: ApiCallConfig, options?: ApiCallOptions) => async ({ setState, getState }: StoreActionApi<any>): Promise<any> => {
    const callDate = new Date().getTime()

    //Get request key
    const requestKey = config.requestName ?
      config.requestName : `${config.method.toLowerCase()} ${config.url}`
      
    //Set authorization token in config
    const sessionState = getStoreState('session')
    if (sessionState && sessionState.token)
      config.authToken = sessionState.token

    //Add custom headers to config
    if (options && options.headers) {
      config.headers = options.headers
    }

    //Set request with 'in progress' status
    setState({
      requests: {
        ...getState().requests,
        [requestKey]: {
          lastCallDate: callDate,
          status: 'inProgress'
        }
      }
    })

    if (options && options.progress) {
      config.onUploadProgress = (progressEvent) => setState({
        requests: {
          ...getState().requests,
          [requestKey]: {
            lastCallDate: callDate,
            status: 'inProgress',
            loaded: `${(progressEvent.loaded / 1000).toFixed(0)}KB / ${(progressEvent.total / 1000).toFixed(0)}KB (${((progressEvent.loaded / progressEvent.total) * 100).toFixed(0)}%)`
          }
        }
      })
    }
    
    
    const res = await api(config)
    if (res.success) {
      setState({
        requests: {
          ...getState().requests,
          [requestKey]: {
            lastCallDate: callDate,
            status: 'success',
            response: res.data,
            message: res.data.message
          }
        }
      })
      return res.data
    } 
    setState({
      requests: {
        ...getState().requests,
        [requestKey]: {
          lastCallDate: callDate,
          status: 'error',
          error: res.error.response && res.error.response.data,
          message: res.message
        }
      }
    })
    return res
  },
  //Add or replace resource in store (all[])
  //Option: set current
  addOrReplaceItemInList: (item: { _id: string, [key:string]: any }, setAsCurrent?: boolean) => 
    ({ setState, getState }: StoreActionApi<any>) => {
      const { all } = getState()
      const existingItemIndex = item._id && all.findIndex((doc: any) => doc._id === item._id)
      if (existingItemIndex >= 0)
        all[existingItemIndex] = item
      else
        all.push(item)
      const newState: any = { all: [...all] }
      if (setAsCurrent && item._id)
        newState.currentId = item._id
      setState(newState)
    },
  removeItemInList: (item: { _id: string, [key:string]: any }) => 
    ({ setState, getState }: StoreActionApi<any>) => {
      const { all } = getState()
      setState({ all: [...all.filter((i: { _id: string, [key:string]: any }) => i._id !== item._id)] })
    }
}



//Persist
const persist = (storeState: any) => (next: any) => (arg: any) => {
  if (storeState.key.match('session')) {
    const toSave = { ...storeState.getState(), ...arg }
    delete toSave.requests
    saveStoreState('session', toSave)
  }
  return next(arg)
}

defaults.middlewares.add(persist)