import * as React from 'react'
import PageCenteredForm from '../app-pages/page-centered-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPassport } from '@fortawesome/free-solid-svg-icons'
import RegisterForm from './register-form'
import { Link } from 'react-router-dom'

const Register: React.FunctionComponent = () => (
  <PageCenteredForm 
    title={ 'Register' }
    titleDescription={ 'Register here' }
    icon={ <FontAwesomeIcon icon={ faPassport } size='2x' /> }
    footer={ <Link to='/login'>Already and account ?</Link> }
  >
    <RegisterForm />
  </PageCenteredForm>
)

export default Register