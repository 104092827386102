import { CurrentMembraneSrnfFormValue, MakeSectionFields, SectionFieldsPath, SectionFieldsPathOptions } from '_containers/membranes-srnf/form'
import { haveOtherValue } from '_utils/membrane-form/common'

const isDisplayed = (currentFormValue: CurrentMembraneSrnfFormValue) => currentFormValue.family === 'Composite'

const buildSupportLayerSectionFields = (
  makeSectionFields: MakeSectionFields,
  currentFormValue: CurrentMembraneSrnfFormValue
): ReturnType<MakeSectionFields> | undefined => {
  let fields: SectionFieldsPathOptions[] = [
    { path: 'supportLayerType' },
    { path: 'supportLayerChemistry' }
  ]
  
  if (haveOtherValue(currentFormValue.supportLayerChemistry))
    fields.push({ path: 'supportLayerChemistryOther' })

  fields = fields.map(v => ({ ...v, colProps: { md: 6 } }))

  return isDisplayed(currentFormValue) ?
    makeSectionFields('Support layer', {}, fields)
    : undefined 
}

export default buildSupportLayerSectionFields