import * as React from 'react'
import Form, { FieldsProps } from '_components/form'
import { useUsers } from '_stores/users/store'
import { CreatePayload } from '_stores/users/types'
import countries from '_constants/countries'

const RegisterForm: React.FunctionComponent = () => {
  const [state, actions] = useUsers()
  React.useEffect(() => {
    actions.clearRequest({ key: 'post /users' })
  }, [])
  const fields: FieldsProps = React.useMemo(() => [
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'Email...',
    },
    {
      name: 'firstName',
      label: 'First name',
      type: 'text',
      placeholder: 'First name...',
    },
    {
      name: 'lastName',
      label: 'Last name',
      type: 'text',
      placeholder: 'Last name...',
    },
    {
      name: 'affiliation',
      label: 'Company',
      type: 'text',
      placeholder: 'Your affiliation...'
    },
    {
      name: 'country',
      label: 'Country',
      type: 'select',
      placeholder: 'Your country...',
      options: countries.map(country => ({
        label: country.name,
        value: country.name
      }))
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      placeholder: 'Password...',
      autoComplete: 'new-password'
    },
    {
      name: 'passwordVerification',
      label: 'Repeat your password',
      type: 'password',
      placeholder: 'Password verification...',
      autoComplete: 'new-password'
    }
  ] as FieldsProps, [])

  return (
    <Form
      fields={ fields }
      initialValues={ {} }
      validate={ (values: any) => {
        const errors: any = {}
        if (!values.email)
          errors.email = 'Required field'
        if (!values.firstName)
          errors.firstName = 'Required field'
        if (!values.lastName)
          errors.lastName = 'Required field'
        if (!values.password)
          errors.password = 'Required field'
        else if (values.password.length < 6)
          errors.password = 'Minimum 6 characters required'
        if (!values.country)
          errors.country = 'Required field'
        if (!values.passwordVerification)
          errors.passwordVerification = 'Required field'
        else if (values.passwordVerification !== values.password)
          errors.passwordVerification = 'Incorrect verification password'
        return errors
      } }
      onSubmit={ ({ passwordVerification, ...values }: CreatePayload) => actions.create({
        ...values,
      }) }
      request={ state.requests['post /users'] }
      success={
        () => <span className='text-success'>An email has been sent to you to validate your account.</span>  
      }
      submitLabel='Register'
      submitLg
    />

  )
}

export default RegisterForm