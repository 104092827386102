import { CurrentMembraneSrnfFormValue, MakeSectionFields } from '_containers/membranes-srnf/form'
import { MEMBRANES_SRNF_SOLUTES_NAME } from '_stores/membranes-srnf/data'
import { sortBy } from 'lodash'

const buildSoluteChoicesSectionFields = (
  makeSectionFields: MakeSectionFields,
  currentFormValue: CurrentMembraneSrnfFormValue,
): ReturnType<MakeSectionFields>[] => {
  let soluteCount = 1
    
  if (currentFormValue.soluteChoice?.multiple) {
    let value = Number(currentFormValue.soluteChoice?.multiple)
    if (value > 15) value = 15
    if (value < 1) value = 1
    soluteCount = value
  } 
  
  const solutesFields = [
    ...Array(Number(soluteCount)).keys()].map(i => {
    const key = `solute${i + 1}`
    return makeSectionFields('', { md: 4 }, [
      { 
        path: `soluteChoice.${key}.category`,
        colProps: { md: 4 },
        fieldPropsBuilder: (currentFormValue, field) => {
          field.type = 'select'
          field.options = [
            ...Object.keys(MEMBRANES_SRNF_SOLUTES_NAME).map(name => (
              { value: name, label: name }
            )),
            { label: 'Other', value: 'Other' }
          ]
        }
      },
      { 
        path: `soluteChoice.${key}.name`,
        colProps: { md: 4 },
        fieldPropsBuilder: (currentFormValue, field) => {
          field.type = 'creatable-select'
          field.noCreateLabel = true
          field.options = []
          const value = currentFormValue.soluteChoice?.[key]?.category
          
          if (value === 'Other')
            field.type = 'text'
          else if (value && MEMBRANES_SRNF_SOLUTES_NAME[value])
            field.options = sortBy(MEMBRANES_SRNF_SOLUTES_NAME[value].map(v => (
              { label: v.name, value: v.name }
            )), 'label')
        
        }
      },
      { 
        path: `soluteChoice.${key}.molecularWeight`,
        colProps: { md: 4 },
        fieldPropsBuilder: (currentFormValue, field) => {
          field.disabled = true
          field.type = 'hard'
          const category = currentFormValue.soluteChoice?.[key]?.category
          const name = currentFormValue.soluteChoice?.[key]?.name?.label
          const soluteNameItem = MEMBRANES_SRNF_SOLUTES_NAME[category]?.find(i => i.name === name)
          if (category === 'Other' || soluteNameItem?.mw === 0) {
            field.disabled = false
          }
          else if (soluteNameItem)
            field.hardValue = soluteNameItem.mw.toString()
        }
      },
      { 
        path: `soluteChoice.${key}.concentration`,
        colProps: { md: 4 }
      },
      { 
        path: `soluteChoice.${key}.concentrationUnit`,
        colProps: { md: 4 }
      },
    ])
  })

  return [
    makeSectionFields('Solute information', { md: 4 }, [
      'soluteChoice.multiple',
    ]),
    ...solutesFields
  ]
}

export default buildSoluteChoicesSectionFields