import * as React from 'react'
import { useAppUser } from '_stores/session/store'
import PageApp from '_containers/app-pages/page-app'
import DescriptionList from '_components/description-list'
import AutoSaveField from '_components/form/auto-save-field'
import UserPasswordForm from './password-form'
import { Row, Col } from 'react-bootstrap'
import Protected from '_components/protected/protected'

const UserProfile:React.FunctionComponent = () => {
  const [currentUser, actions] = useAppUser() 

  const infosFields = React.useMemo(() => [
    {
      label: 'First name',
      value: <AutoSaveField 
        field={ {
          name: 'firstName',
          type: 'text'
        } }
        onSubmit={ (value) => actions.updateProfile({
          firstName: value
        }) }
        initialValue={ currentUser.firstName }
      />
    },
    {
      label: 'Last name',
      value: <AutoSaveField 
        field={ {
          name: 'lastName',
          type: 'text'
        } }
        onSubmit={ (value) => actions.updateProfile({
          lastName: value
        }) }
        initialValue={ currentUser.lastName }
      />
    },
    {
      label: 'Email',
      value: <AutoSaveField 
        field={ {
          name: 'email',
          type: 'text'
        } }
        onSubmit={ (value) => actions.updateProfile({
          email: value
        }) }
        disabled={ Boolean(currentUser?.role === 'user') }
        initialValue={ currentUser.email }
      />
    }
  ], [currentUser])
  
  const notificationsFields = React.useMemo(() => [
    {
      label: 'When a new membrane is created from the website',
      value: <AutoSaveField 
        field={ {
          name: 'membraneCreated',
          type: 'checkbox',
          options: [{ label: ' ', value: true }]
        } }
        forceEditableMode
        onSubmit={ (value) => actions.updateProfile({
          notifications: {
            ...currentUser.notifications,
            membraneCreated: value
          }
        }) }
        initialValue={ currentUser.notifications.membraneCreated }
      />
    },
    {
      label: 'When a membrane is reported from the website',
      value: <AutoSaveField 
        field={ {
          name: 'membraneReported',
          type: 'checkbox',
          options: [{ label: ' ', value: true }]
        } }
        forceEditableMode
        onSubmit={ (value) => actions.updateProfile({
          notifications: {
            ...currentUser.notifications,
            membraneReported: value
          }
        }) }
        initialValue={ currentUser.notifications.membraneReported }
      />
    }
  ], [currentUser])

  return (
    <PageApp>
      <h2>Profile informations</h2>
      <hr />
      <DescriptionList
        items={ infosFields }
      />
      <Protected roles={ ['admin'] }>

        <h2 className='mt-4'>Notifications</h2>
        <hr />
        <DescriptionList
          items={ notificationsFields }
        />
      </Protected>
      <h2 className='mt-4'>Password</h2>
      <hr />
      <Row>
        <Col md={ 6 }>
          <UserPasswordForm />
        </Col>
      </Row>
    </PageApp>
  )
}

export default UserProfile