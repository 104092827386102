import { faPassport } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PageCenteredForm from '_containers/app-pages/page-centered-form'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import qs from 'query-string'
import Loader from '_components/loader'
import { useUsers } from '_stores/users/store'
import { Button } from 'react-bootstrap'

interface AccountValidationProps {}

const AccountValidation: React.FC<AccountValidationProps> = ({}) => {
  const location: any = useLocation()
  const searchParams = qs.parse(location.search)
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState(false)


  const [state, actions] = useUsers()

  const validateAccount = async () => {

    const res = await actions.accountValidation({ validationToken: searchParams.token as string })
    console.log(res)
    if (res && !res.ok && !success)
      setError(res.message || 'Invalid link')
    else
      setSuccess(true)
  }

  React.useEffect(() => {
    if (searchParams.token) 
      validateAccount()
  }, [])

  return (
    <PageCenteredForm
      title={ 'Account validation' }
      icon={ <FontAwesomeIcon icon={ faPassport } size='2x' /> }
    >
      {
        (!searchParams.token || error) ?
          <div className='text-center'>
            <div>ERROR: {error || 'Invalid link'}</div>
            <Button as={ Link } to='/login' className='mt-3'>Login</Button>
          </div>
          :
          success ? 
            <div className='text-center'>
              <div>Account validated! You can now connect.</div>
              <Button as={ Link } to='/login' className='mt-3'>Login</Button>
            </div>
            :
            <Loader />
      }
    </PageCenteredForm>)
}

export default AccountValidation