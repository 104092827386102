import CreateUser from './containers/users/create'
import Users from './containers/users/list'
import User from './containers/users/item'
import Login from '_containers/login'
import Logout from '_containers/logout'
import PasswordRecovery from '_containers/password-recovery'
import PasswordReset from '_containers/password-reset'
import IsAuth from './containers/is-auth'
import UserProfile from '_containers/user-profile'
import MediasList from '_containers/medias/list'
import ArticlesList from '_containers/articles/list'
import ArticleItem from '_containers/articles/item'
import ReportsList from '_containers/reports/list'
import ReportItem from '_containers/reports/item'
import MembranesList from '_containers/membranes/list'
import MembraneItem from '_containers/membranes/item'
import MembranesSrnfList from '_containers/membranes-srnf/list'
import MembraneSrnfItem from '_containers/membranes-srnf/item'
import Register from '_containers/users/register'
import AccountValidation from '_containers/users/account-validation'

interface Route {
  path: string
  component: any
  logged?: boolean | 'common'
  roles?: ('user' | 'admin')[]
}

const routes: Route[] = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/logout',
    component: Logout,
    logged: true
  },
  {
    path: '/password-recovery',
    component: PasswordRecovery
  },
  {
    path: '/reset-password',
    component: PasswordReset
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/news',
    component: ArticlesList,
    logged: true,
    roles: ['admin']
  },
  {
    path: '/news/:id',
    component: ArticleItem,
    logged: true,
    roles: ['admin']
  },
  {
    path: '/releases',
    component: ArticlesList,
    logged: true,
    roles: ['admin']
  },
  {
    path: '/releases/:id',
    component: ArticleItem,
    logged: true,
    roles: ['admin']
  },
  {
    path: '/reports',
    component: ReportsList,
    logged: true,
    roles: ['admin']
  },
  {
    path: '/reports/:id',
    component: ReportItem,
    logged: true,
    roles: ['admin']
  },
  {
    path: '/membranes-srnf',
    component: MembranesSrnfList,
    logged: true
  },
  {
    path: '/membranes-srnf/:id',
    component: MembraneSrnfItem,
    logged: true
  },
  {
    path: '/membranes',
    component: MembranesList,
    logged: true
  },
  {
    path: '/membranes/:id',
    component: MembraneItem,
    logged: true
  },
  {
    path: '/medias',
    component: MediasList,
    logged: true,
    roles: ['admin']
  },
  {
    path: '/users/create',
    component: CreateUser
  },
  {
    path: '/users',
    component: Users,
    logged: true,
    roles: ['admin']
  },
  {
    path: '/users/contributors',
    component: Users,
    logged: true,
    roles: ['admin']
  },
  {
    path: '/users/account-validation',
    component: AccountValidation
  },
  {
    path: '/users/:id',
    component: User,
    logged: true,
    roles: ['admin']
  },
  {
    path: '/is-auth',
    component: IsAuth,
    logged: 'common'
  },
  {
    path: '/me',
    component: UserProfile,
    logged: true
  }
]

export const getRoute = (path: string): Route | undefined =>
  routes.find(r => r.path === path)

export default routes