import * as React from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import ConfirmationModal from '_components/confirmation-modal'
import AutoSaveField from '_components/form/auto-save-field'
import { Membrane, Status } from '_stores/membranes/types'
import StatusComponent from '_components/status'
import { getStatusVariant } from '_stores/membranes/utils'
import { MembraneSrnf } from '_stores/membranes-srnf/types'

interface MembraneItemStatusProps {
  membrane: Membrane | MembraneSrnf
  actions: any
  state: any
}

const MembraneItemStatus: React.FunctionComponent<MembraneItemStatusProps> = ({
  membrane,
  actions,
  state
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [newMembraneStatus, setNewMembraneStatus] = React.useState<Status | undefined>()
  const [comment, setComment] = React.useState<string | undefined>()

  const clear = React.useCallback(
    () => {
      setModalIsOpen(false)
      setComment(undefined)
      setNewMembraneStatus(membrane.status)
    },
    [membrane, comment, newMembraneStatus],
  )

  const save = React.useCallback(
    async () => {
      if (membrane._id) {
        await actions.update({
          _id: membrane._id,
          status: newMembraneStatus
        })
        if (comment) {
          actions.createNote({
            membraneId: membrane._id,
            comment
          })
        }
      }
      clear()
    },
    [membrane, comment, newMembraneStatus],
  )

  return (
    <>
      <ConfirmationModal
        isOpen={ modalIsOpen }
        title='Membrane status'
        description={
          <div>
            <p>
              Are you sure you want to change the status to:
              <br />
              { newMembraneStatus && 
                <strong>
                  <StatusComponent
                    status={ newMembraneStatus }
                    variant={ getStatusVariant(newMembraneStatus) }
                  />
                </strong>
              }
            </p>
            <h6 className='mt-4'>Optional comment:</h6>
            <AutoSaveField
              field= { {
                type: 'textarea',
                name: 'comment'
              } }
              onSubmit={ (value: string) => setComment(value) }
              forceEditableMode
            />
          </div>
        }
        confirm={ save }
        cancel={ clear }
      />

      <DropdownButton
        id='dropdown-basic-button'
        title={ membrane.status }
        size='sm'
        variant={ getStatusVariant(membrane.status) }
      >
        {
          state.schema && state.schema.status && state.schema.status.validations.enum.filter((value: Status) => 
            value !== membrane.status
          ).map((value: Status) =>
            <Dropdown.Item
              key={ value }
              onClick={ () => {
                setModalIsOpen(true)
                setComment(undefined)
                setNewMembraneStatus(value)
              } }
            >
              <StatusComponent
                status={ value }
                variant={ getStatusVariant(value) }
              />
            </Dropdown.Item>
          )
        }
      </DropdownButton>
    </>
  )
}

export default MembraneItemStatus