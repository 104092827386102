import * as React from 'react'
import PageCenteredForm from '../app-pages/page-centered-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPassport } from '@fortawesome/free-solid-svg-icons'
import LoginForm from './form'
import { Link } from 'react-router-dom'

const Login: React.FunctionComponent = () => (
  <PageCenteredForm 
    title={ 'Login' }
    titleDescription={ 'Login here' }
    icon={ <FontAwesomeIcon icon={ faPassport } size='2x' /> }
    footer={
      <div className='d-flex justify-content-between'>
        <Link to='/password-recovery'>Forgot your password?</Link>
        <Link to='/register'>Register</Link>
      </div> }
  >
    <LoginForm />
  </PageCenteredForm>
)

export default Login