import { CurrentMembraneSrnfFormValue, MakeSectionFields } from '_containers/membranes-srnf/form'
import { Structure } from '_stores/membranes-srnf/types'
import { hasStructure } from '_stores/membranes-srnf/utils'

const isDisplayed = (currentFormValue: CurrentMembraneSrnfFormValue) =>
  currentFormValue.family === 'Composite' && currentFormValue.structure && hasStructure(currentFormValue.structure, Structure.tfn)

export const buildTfnDataSectionFields = (
  makeSectionFields: MakeSectionFields,
  currentFormValue: CurrentMembraneSrnfFormValue
): ReturnType<MakeSectionFields> | undefined =>
  isDisplayed(currentFormValue) ? makeSectionFields('TFN', '', [ 
    { path: 'tfnData.positionOfNanoparticles', colProps: { md: 4 } },
    { path: 'tfnData.nanomaterialName', colProps: { md: 4 } },
    { path: 'tfnData.nanomaterialConcentration', colProps: { md: 4 } },
    { path: 'tfnData.nanomaterialConcentrationUnit', colProps: { md: 4 } },
  ]) : undefined