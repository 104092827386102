import React from 'react'
import { CurrentMembraneSrnfFormValue, MakeSectionFields, SectionFieldsPathOptions } from '_containers/membranes-srnf/form'
import { getOptionFromEnum } from '_utils/membrane-form/common'
import { Report } from '_stores/reports/types'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const buildReportSectionFields = (
  makeSectionFields: MakeSectionFields,
  currentFormValue: CurrentMembraneSrnfFormValue,
  reports: Report[],
  schema?: any,
  currentReport?: Report,
  showForm?: boolean
): ReturnType<MakeSectionFields> | undefined => {
  const fields: SectionFieldsPathOptions[] = [
    {
      path: 'report.reference',
      fieldPropsBuilder: (currentFormValue, field) => {
        if (!showForm) {
          field.type = 'creatable-select'
          field.label = currentFormValue?.family === 'Commercial' ? 'Report reference (DOI, product datasheet URL, patent number)' : 'Report reference (DOI, patent number)',
          field.options = reports.map(report => ({
            label: report.reference,
            value: report._id
          }))
          field.info = currentReport && currentReport._id && 
          <span>
            <OverlayTrigger
              trigger='focus' 
              placement={ 'bottom' }
              overlay={
                <Popover id={ 'popover-positioned-bottom' } style={ { maxWidth: 400 } }>
                  <Popover.Title as='h3'>{ currentReport.reference }</Popover.Title>
                  <Popover.Content>
                    <strong>{ currentReport.type }</strong>&nbsp;
                    by { currentReport.author && currentReport.author.lastName } ({currentReport.publicationDate && new Date(currentReport.publicationDate).getFullYear()})<br /><br />
                    { currentReport.title }<br /><br />
                    { currentReport.link && <a href={ currentReport.link } target='__blank'>{ currentReport.link }</a> }
                  </Popover.Content>
                </Popover>
              }
            >
              <span>                
                <Button variant='link' className='p-0 mr-1'>
                  <FontAwesomeIcon icon={ faInfoCircle }/>
                </Button>
              </span>
            </OverlayTrigger>
            { currentReport.link && <a href={ currentReport.link } target='__blank'>{ currentReport.link }</a> }
          </span>
          
          
          currentReport && currentReport.link ?
            <a href={ currentReport.link } target='__blank'>{ currentReport.link }</a>
            : 'Select option or write a report\'s reference to create it'
        } else {
          field.type = 'text'
          field.label = 'Reference'
        }
      }
    }
  ]
    
  if (showForm) {
    fields.push({
      path: 'report.title',
      colProps: { md: 6 }, 
      fieldPropsBuilder: (currentFormValue, field) => {
        field.label = field.placeholder = 'Title'
      }
    })
    fields.push({ 
      path: 'report.type', 
      colProps: { md: 6 }, 
      fieldPropsBuilder: (currentFormValue, field) => {
        field.type = 'select'
        field.label = 'Type'
        field.placeholder = 'Type...'
        field.options = schema && getOptionFromEnum(schema.type.validations.enum)
      }
    })
    fields.push({ 
      path: 'report.author.lastName',
      colProps: { md: 6 }, 
      fieldPropsBuilder: (currentFormValue, field) => {
        field.label = field.placeholder = 'Author last name'
      }
    })
    fields.push({ 
      path: 'report.publicationDate',
      colProps: { md: 6 }, 
      fieldPropsBuilder: (currentFormValue, field) => {
        field.label = 'Year of publication'
        field.placeholder = '2021...'
        field.type = 'number'
        field.min = 1900
        field.max = new Date().getFullYear()
      }
    })
  }
  
  return makeSectionFields('Report', { md: showForm ? 6 : 12 }, fields)
}

export default buildReportSectionFields