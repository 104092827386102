import { CurrentMembraneSrnfFormValue, MakeSectionFields } from '_containers/membranes-srnf/form'

const isDisplayed = (currentFormValue: CurrentMembraneSrnfFormValue) => 
  currentFormValue.family === 'Composite' && currentFormValue.topLayerDepositionMethod === 'Interfacial Polymerization'

const buildInterfacialPolymerizationSectionFields = (
  makeSectionFields: MakeSectionFields,
  currentFormValue: CurrentMembraneSrnfFormValue,
  phaseNumber: 1 | 2
): ReturnType<MakeSectionFields> | undefined => 
  isDisplayed(currentFormValue) ?
    makeSectionFields(`Interfacial Polymerization - phase ${phaseNumber}`, {}, [
      { path: `interfacialPolymerization.phase${phaseNumber}`, colProps: { md: 4 } },
      { path: `interfacialPolymerization.monomer${phaseNumber}Smiles`, colProps: { md: 8 } },
      { path: `interfacialPolymerization.monomer${phaseNumber}Concentration`, colProps: { md: 4 } },
      { path: `interfacialPolymerization.monomer${phaseNumber}ConcentrationUnit`, colProps: { md: 4 } },
      { path: `interfacialPolymerization.additive${phaseNumber}Smiles`, colProps: { md: 4 } },
      { path: `interfacialPolymerization.additive${phaseNumber}Concentration`, colProps: { md: 4 } },
      { path: `interfacialPolymerization.additive${phaseNumber}ConcentrationUnit`, colProps: { md: 4 } },
    ]) : undefined

export default buildInterfacialPolymerizationSectionFields

