import { CurrentMembraneSrnfFormValue } from '_containers/membranes-srnf/form'

const customValidations = (currentFormValue: CurrentMembraneSrnfFormValue) => {
  const validations = [
    { path: 'commercialName', rules: { required: true } },
    { path: 'topLayerDepositionMethod', rules: { required: true } },
    { path: 'interfacialPolymerization.phase1', rules: { required: true, minLength: 1 } },
    { path: 'interfacialPolymerization.monomer1Smiles', rules: { required: true } },
    { path: 'interfacialPolymerization.monomer1Concentration', rules: { required: true } },
    { path: 'interfacialPolymerization.monomer1ConcentrationUnit', rules: { required: true } },
    //{ path: 'interfacialPolymerization.additive1Smiles', rules: { required: true } },
    //{ path: 'interfacialPolymerization.additive1Concentration', rules: { required: true } },
    { path: 'interfacialPolymerization.phase2', rules: { required: true, minLength: 1 } },
    { path: 'interfacialPolymerization.monomer2Smiles', rules: { required: true } },
    { path: 'interfacialPolymerization.monomer2Concentration', rules: { required: true } },
    { path: 'interfacialPolymerization.monomer2ConcentrationUnit', rules: { required: true } },
    //{ path: 'interfacialPolymerization.additive2Smiles', rules: { required: true } },
    //{ path: 'interfacialPolymerization.additive2Concentration', rules: { required: true } },
    //{ path: 'interfacialPolymerization.additive1ConcentrationUnit', rules: { required: true } },
    //{ path: 'interfacialPolymerization.additive2ConcentrationUnit', rules: { required: true } },
    { path: 'tfnData.positionOfNanoparticles', rules: { required: true } },
    { path: 'tfnData.nanomaterialName', rules: { required: true } },
    { path: 'tfnData.nanomaterialConcentration', rules: { required: true } },
    { path: 'tfnData.nanomaterialConcentrationUnit', rules: { required: true } },
    { path: 'postTreatment', rules: { required: true, minLength: 1 } },
    { path: 'postTreatmentChemicalAgentSmiles', rules: { required: true } },
    { path: 'postTreatmentChemicalAgentConcentration', rules: { required: true } },
    { path: 'postTreatmentChemicalAgentConcentrationUnit', rules: { required: true } },
    { path: 'supportLayer.postTreatment', rules: { required: true, minLength: 1 } },
    { path: 'supportLayer.postTreatmentChemicalAgentSmiles', rules: { required: true } },
    { path: 'supportLayer.postTreatmentChemicalAgentConcentration', rules: { required: true } },
    { path: 'supportLayer.postTreatmentChemicalAgentConcentrationUnit', rules: { required: true } },
    { path: 'isaSupportLayer.polymerWeightConcentrationInDopeSolution', rules: { required: true } },
    { path: 'isaSupportLayer.solvent', rules: { required: true, minLength: 1 } },
    { path: 'isaSupportLayer.solventRatio', rules: { required: true, ratio: currentFormValue.isaSupportLayer?.solvent?.length || 2 } },
    { path: 'isaSupportLayer.nonSolvent', rules: { required: true, minLength: 1 } },
    // { path: 'isaSupportLayer.additivesInDopeSolutionSmiles', rules: { required: true } },
    // { path: 'isaSupportLayer.dopeSolutionAdditiveConcentration', rules: { required: true } },
    // { path: 'isaSupportLayer.additiveInCoagulationMediumSmiles', rules: { required: true } },
    // { path: 'isaSupportLayer.coagulationMediumAdditivesConcentration', rules: { required: true } },
    // { path: 'isaSupportLayer.dopeSolutionAdditiveConcentrationUnit', rules: { required: true } },
    // { path: 'isaSupportLayer.coagulationMediumAdditivesConcentrationUnit', rules: { required: true } },
    //{ path: 'isaSupportLayer.castingThickness', rules: { required: true } },
    { path: 'supportLayerType', rules: { required: true } },
    { path: 'type', rules: { required: true } },
    { path: 'chemistry', rules: { required: true, minLength: 1 } },
    { path: 'structure', rules: { required: true, minLength: 1 } },
    { path: 'postTreatmentOther', rules: { required: true } },
    { path: 'supportLayer.postTreatmentOther', rules: { required: true } },
    { path: 'chemistryOther', rules: { required: true } },
    { path: 'supportLayerChemistry', rules: { required: true } },
    { path: 'supportLayerChemistryOther', rules: { required: true } },
    { path: 'testConditions.solventRatio', rules: { required: true, ratio: 2 } },
    { path: 'testConditions.solvent2', rules: { required: true } },
    //{ path: 'characterizationResults.topLayerThickness', rules: { required: true } },
    // { path: 'characterizationResults.roughness', rules: { required: true } },
    // { path: 'characterizationResults.contactAngle', rules: { required: true } },
    // { path: 'characterizationResults.totalThickness', rules: { required: true } },
    // { path: 'characterizationResults.characterizationData', rules: { required: true } },
    { path: 'commercialNameOther', rules: { required: true } },
    { path: 'commercialNameOtherManufacturer', rules: { required: true } },
    { path: 'testConditions.hydraulicP', rules: { required: true } },
    { path: 'testConditions.temperature', rules: { required: true } },
    //{ path: 'filtrationResults.permeance', rules: { required: true } },
  ]

  for (let i = 0; i <= 14; i++) {
    //validations.push({ path: `soluteChoice.solute${i}.category`, rules: { required: true } })
    //validations.push({ path: `soluteChoice.solute${i}.name`, rules: { required: true } })
    //validations.push({ path: `soluteChoice.solute${i}.molecularWeight`, rules: { required: true, min: 0, max: 1500 } })
    //validations.push({ path: `soluteChoice.solute${i}.concentration`, rules: { required: true } })
    //validations.push({ path: `soluteChoice.solute${i}.concentrationUnit`, rules: { required: true } })
    //validations.push({ path: `filtrationResults.rejection${i + 1}`, rules: { required: true } })
  }

  return validations
}

export default customValidations