import { CurrentMembraneSrnfFormValue, MakeSectionFields } from '_containers/membranes-srnf/form'

const buildCharacterizationResultsSectionFields = (
  makeSectionFields: MakeSectionFields,
  currentFormValue: CurrentMembraneSrnfFormValue,
): ReturnType<MakeSectionFields> | undefined => {
  let fields = [
    'characterizationResults.roughness',
    'characterizationResults.contactAngle',
    'characterizationResults.totalThickness',
    'characterizationResults.characterizationData'
  ]
  if (currentFormValue.family === 'Composite')
    fields = ['characterizationResults.topLayerThickness', ...fields]

  return makeSectionFields('Characterization results', { md: 4 }, fields)
}

export default buildCharacterizationResultsSectionFields