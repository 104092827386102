import * as React from 'react'
import { useUsers } from '_stores/users/store'
import Title from '_components/title'
import Table from '_components/table'
import { Link, useLocation } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import InviteUserModal from './invite-modal'
import _ from 'lodash'

const UsersList: React.FunctionComponent = () => {
  const [state, actions] = useUsers()


  const location = useLocation()

  React.useEffect(() => {
    actions.fetch()
  }, [])

  //Invite modal
  const [inviteModalIsOpen, setInviteModalOpen] = React.useState(false)

  const isContributorsList = location.pathname.match('contributors')
  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (d: any) => 
          <Link to={ `/users/${d._id}` }>
            { d.disabled && <FontAwesomeIcon icon={ faBan } className='mr-1' />}
            {
              d.firstName ?
                <span>{ d.firstName } { d.lastName }</span>
                : 'N/A'
            }
            
          </Link>
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: '30%'
      },
      {
        Header: 'Affiliation',
        accessor: 'affiliation',
        width: '20%'
      },
      {
        Header: 'Role',
        accessor: 'role',
        width: '20%'
      }
    ],
    []
  )

  const users = React.useMemo(() => _.sortBy(
    state.all.filter(u => u.role !== 'contributor'),
    'firstName'), [state.all])
  
  const contributors = React.useMemo(() => _.sortBy(
    state.all.filter(u => u.role === 'contributor'),
    'firstName'), [state.all])

  return (
    <PageApp>
      <Title
        label={ isContributorsList ? 'Contributors' : 'Users' }
        button={ !isContributorsList && {
          label: 'Invite user',
          onClick: () => setInviteModalOpen(true)
        } }
      />
      {
        isContributorsList ?
          <div className='mt-5'>
            <h4>Contributors</h4>
            <Table
              columns={ columns }
              data={ contributors }
            />
          </div> :
          <div className='mt-3'>
            <Table
              columns={ columns }
              data={ users }
            />
          </div>
      }
      <InviteUserModal
        isOpen={ inviteModalIsOpen }
        setOpen={ (isOpen: boolean) => setInviteModalOpen(isOpen) }
      />
    </PageApp>
  )
}

export default UsersList