import * as React from 'react'
import Form from '_components/form'
import { useUsers } from '_stores/users/store'
import { ResetPasswordPayload } from '_stores/users/types'

interface PasswordResetFormProps {
  token: string
  success?: React.ReactNode
}

const PasswordResetForm: React.FunctionComponent<PasswordResetFormProps> = ({ token, success }) => {
  const [state, actions] = useUsers()
  return (
    <Form
      fields={ [
        {
          name: 'password',
          type: 'password',
          placeholder: 'Password...'
        },
        {
          name: 'passwordVerification',
          type: 'password',
          placeholder: 'Password verification...'
        }
      ] }
      initialValues={ {
        token
      } }
      validate={ (values: any) => {
        const errors: any = {}
        if (!values.password)
          errors.password = 'Required field'
        else if (values.password.length < 6)
          errors.password = 'Minimum 6 characters required'
        if (!values.passwordVerification)
          errors.passwordVerification = 'Required field'
        else if (values.passwordVerification !== values.password)
          errors.passwordVerification = 'Incorrect verification password'
        return errors
      } }
      onSubmit={ (values: ResetPasswordPayload) => actions.resetPassword(values) }
      request={ state.requests && state.requests['post /users/reset-password'] }
      success={ success }
      submitLabel='Set new password'
      submitLg
    />
  )
}

export default PasswordResetForm