import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends, faUserCircle, faSignOutAlt, faIdCard, faImages, faNewspaper, faTint, faStickyNote, faFileAlt, faPlusCircle, faUserAstronaut, faUsers, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { ProSidebar, Menu, SidebarContent, SidebarHeader, SubMenu } from 'react-pro-sidebar'
import SidebarLink from './link'
import { useAppUser } from '_stores/session/store'
import { useLocation } from 'react-router-dom'
import { useMembranes } from '_stores/membranes/store'
import { useMembranesSrnf } from '_stores/membranes-srnf/store'
import Protected from '_components/protected/protected'

interface SideBarProps {
  collapsed?: boolean
  toggled?: boolean
  setToggled(toggled: boolean): void
}

const Sidebar: React.FunctionComponent<SideBarProps> = ({
  collapsed,
  toggled,
  setToggled
}) => {
  const [appUser] = useAppUser()
  const location = useLocation()
  const [membranesState, membranesActions] = useMembranes()
  const [membranesSrnfState, membranesSrnfActions] = useMembranesSrnf()

  return (
    <ProSidebar
      className='sidebar'
      breakPoint='md'
      collapsed={ collapsed }
      toggled={ toggled }
    >
      <SidebarHeader>
        <div className='sidebar_header'>
          OMD 2024
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu>
          <SubMenu
            title={ `${appUser.firstName} ${appUser.lastName}` }
            icon={ <FontAwesomeIcon icon={ faUserCircle } /> }
          >
            <SidebarLink
              to='/me'
              setToggled={ setToggled }
              icon={ <FontAwesomeIcon icon={ faIdCard } /> }
              label={ <span>My profile</span> }
            />
            <SidebarLink
              to='/logout'
              setToggled={ setToggled }
              icon={ <FontAwesomeIcon icon={ faSignOutAlt } /> }
              label={ <span>Logout</span> }
            />
          </SubMenu>
        </Menu>
        <Menu>
          <SidebarLink
            to='/membranes'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faTint } /> }
            label={ <span>Membranes RO</span> }
          />
          {
            location.pathname.match('membranes') && !location.pathname.match('srnf') ?
              <SidebarLink
                to='/membranes/new'
                setToggled={ setToggled }
                icon={ <FontAwesomeIcon icon={ faPlusCircle } /> }
                label={ <span>New membrane RO</span> }
                className='sub-link'
                onClick={ () => {
                  membranesActions.setDuplicate()
                  membranesActions.setCurrent()
                } }
              />
              : <span />
          }
          <SidebarLink
            to='/membranes-srnf'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faTint } /> }
            label={ <span>Membranes SRNF</span> }
          />
          {
            location.pathname.match('membranes-srnf') ?
              <>
                <SidebarLink
                  to='/membranes-srnf/new'
                  setToggled={ setToggled }
                  icon={ <FontAwesomeIcon icon={ faPlusCircle } /> }
                  label={ <span>New membrane SRNF</span> }
                  className='sub-link'
                  onClick={ () => {
                    membranesSrnfActions.setDuplicate()
                    membranesSrnfActions.setCurrent()
                  } }
                />
                <SidebarLink
                  href='https://openmembranedatabase.org/Readme_OMD4SRNF_final.pdf'
                  setToggled={ setToggled }
                  icon={ <FontAwesomeIcon icon={ faFilePdf } /> }
                  label={ <span>Readme for submissions</span> }
                  className='sub-link'
                />
              </>
              : <span />
          }
          <Protected roles={ ['admin'] }>
            <SidebarLink
              to='/reports'
              setToggled={ setToggled }
              icon={ <FontAwesomeIcon icon={ faStickyNote } /> }
              label={ <span>Reports</span> }
            />
            <SidebarLink
              to='/news'
              setToggled={ setToggled }
              icon={ <FontAwesomeIcon icon={ faNewspaper } /> }
              label={ <span>News</span> }
            />
            { /*<SidebarLink
            to='/releases'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faFileAlt } /> }
            label={ <span>Releases</span> }
          /> */ }
            <SidebarLink
              to='/medias'
              setToggled={ setToggled }
              icon={ <FontAwesomeIcon icon={ faImages } /> }
              label={ <span>Medias</span> }
            />
            <SidebarLink
              to='/users'
              setToggled={ setToggled }
              icon={ <FontAwesomeIcon icon={ faUserFriends } /> }
              label={ <span>Users</span> }
            />

            {
              location.pathname.match('users') ?
                <>
                  <SidebarLink
                    to='/users'
                    setToggled={ setToggled }
                    icon={ <FontAwesomeIcon icon={ faUsers } /> }
                    label={ <span>BO Users</span> }
                    className='sub-link'
                  />
                  <SidebarLink
                    to='/users/contributors'
                    setToggled={ setToggled }
                    icon={ <FontAwesomeIcon icon={ faUserAstronaut } /> }
                    label={ <span>Contributors</span> }
                    className='sub-link'
                  />
                </>
                : <span />
            }
          </Protected>
        </Menu>
      </SidebarContent>
    </ProSidebar>
  )
}

export default Sidebar