import * as React from 'react'
import Field, { BaseFormFieldProps } from '../index'
import Select from 'react-select'
import { cloneDeep } from 'lodash'
import { sortSelectOptions } from '_utils/array'

export interface SelectOptions {
  value?: string
  label: string
  [key: string]: any
}

export interface ReactSelectProps extends BaseFormFieldProps {
  options: SelectOptions[]
  multiple?: boolean
  canMultiSelectSameValue?: boolean
}

export const InputField: React.FunctionComponent<ReactSelectProps> = (props) => {
  const options = React.useMemo(() => {
    if (!props.canMultiSelectSameValue) return props.options
    const duplicatedOptions = cloneDeep(props.options)
    const defaultValues = {}
    duplicatedOptions.forEach(option => {
      defaultValues[option.value || option.label] = 0
    })
    props.field.value?.forEach(value => {
      const defaultValue = value.replace(/\$\$(.*)$/, '')
      defaultValues[defaultValue] += 1
    })
    for (const key in defaultValues) {
      if (defaultValues[key]) {
        for (let i = 0; i < defaultValues[key]; i++) {
          duplicatedOptions.push({ value: `${key}$$${i}`, label: key })
        }
      }
    }
    
    return duplicatedOptions
  }, [props.canMultiSelectSameValue, props.options, props.field?.value])

  const defaultValue = React.useMemo(() => {
    if (!props.defaultValue && props.multiple)
      return []
    else if (!props.defaultValue)
      return {}
    if (Array.isArray(props.defaultValue)) {
      const countValues = {}
      return props.defaultValue.map((value: string) => {
        if (countValues[value] === undefined) countValues[value] = 0
        else countValues[value] += 1
        const option = cloneDeep(options.find(o => o.value === value))

        if (props.canMultiSelectSameValue && option && countValues[value]) option.value = `${option.value}$$${countValues[value]}`

        return option
      })
    }
    return props.options.find(o => o.label === props.defaultValue)
  }, [props.defaultValue, props.options])
  
  return(
    <Field { ...props }>
      <Select
        defaultValue={ defaultValue || [] }
        isMulti={ props.multiple }
        name={ props.name }
        options={ sortSelectOptions(options) }
        closeMenuOnSelect={ false }
        //isOptionSelected={ (option, selectValue) => selectValue.some(i => i === option) }
        onChange={ (selectedOption) => {
          if (!props.onChange)
            return
          if (Array.isArray(selectedOption))
            return props.onChange(selectedOption && selectedOption.map((o: any) => o.value))
          return props.onChange(selectedOption)
        } }
        classNamePrefix='c-field-react-select'
        //className= {
        /*classNames('c-field-react-select', {
            'is-valid': !props.hideValid && !Boolean(props.error),
            'is-invalid': Boolean(props.error)
          })*/
        //}
      />
    </Field>
  )
}
export default InputField