import { CurrentMembraneSrnfFormValue, MakeSectionFields, SectionFieldsPathOptions } from '_containers/membranes-srnf/form'
import { get } from 'lodash'

const buildPostTreatmentSectionFields = (
  makeSectionFields: MakeSectionFields,
  currentFormValue: CurrentMembraneSrnfFormValue,
  postTreatmentPath: string,
  postTreatmentDataPath: string,
  title: string,
  units?: string[],
  hideForCommercialAndISA?: boolean
): ReturnType<MakeSectionFields>[] => {
  const postTreatmentFields: SectionFieldsPathOptions[] = [{ path: postTreatmentPath, colProps: 4 }]
  const postTreatmentSubFields: SectionFieldsPathOptions[][] = []

  const currentValue = get(currentFormValue, postTreatmentPath) as string[]

  if (hideForCommercialAndISA && (currentFormValue.family === 'Commercial' || currentFormValue.family === 'ISA/inorganic/dense')) return []

  currentValue?.forEach((postTreatment: string, index: number) => {
    let fields: SectionFieldsPathOptions[] | undefined
    const colProps = { md: 4 }

    const postTreatmentIsDryingOrThermalTreatment = postTreatment === 'Drying' || postTreatment === 'Thermal treatment'
    if (postTreatment !== 'None') {
      fields = [
        {
          path: `${postTreatmentDataPath}[${index}].postTreatment`,
          colProps,
          fieldPropsBuilder: (currentFormValue, field, validations) => {
            if (postTreatment === 'Other')
              validations[field.path] = { required: true }
            field.label = field.placeholder = postTreatment === 'Other' ? `${title} name` : title
            field.type = 'text'
            field.disabled = Boolean(postTreatment !== 'Other')
            field.hardValue = postTreatment !== 'Other' ? postTreatment : undefined
          }
        },
      ]

      if (!postTreatmentIsDryingOrThermalTreatment) {
        fields.push({
          path: `${postTreatmentDataPath}[${index}].postTreatmentChemicalAgentSmiles`,
          colProps,
          fieldPropsBuilder: (currentFormValue, field, validations) => {
            field.label = field.placeholder = 'Chemical agent SMILES'
            field.info = 'for mixtures, separate by ;'
            validations[field.name] = { required: true }
          }
        })

        if (postTreatment !== 'Pure solvent treatment') {
          fields.push({
            path: `${postTreatmentDataPath}[${index}].postTreatmentChemicalAgentConcentration`,
            colProps,
            fieldPropsBuilder: (currentFormValue, field, validations) => {
              field.label = field.placeholder = 'Chemical agent concentration'
              field.info = 'for mixtures, separate by ;'
              validations[field.path] = { required: true }
            }
          })
          fields.push({
            path: `${postTreatmentDataPath}[${index}].postTreatmentChemicalAgentConcentrationUnit`,
            colProps,
            fieldPropsBuilder: (currentFormValue, field, validations) => {
              field.label = field.placeholder = 'Chemical agent concentration Unit'
              field.info = 'for mixtures, separate by ;'
              field.type = 'react-select'
              field.options = (units || []).map(unit => ({ value: unit, label: unit }))
              field.multiple = true
              validations[field.path] = { required: true }
              field.canMultiSelectSameValue = true
            }
          })
        }
      }
    }
    if (fields) postTreatmentSubFields.push(fields)
  })

  return [
    makeSectionFields(title, {}, postTreatmentFields),
    ...postTreatmentSubFields.map((postTreatmentFields, index) =>
      makeSectionFields(
        `${title} - ${currentValue?.[index]}`,
        { md: 3 },
        postTreatmentFields,
        { sectionTitleSize: 5 }
      )
    ),
  ]
}

export default buildPostTreatmentSectionFields

