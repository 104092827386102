import * as React from 'react'
import Form from '_components/form'
import { useCurrentMembrane } from '_stores/membranes/store'
import { useCurrentMembrane as useSrnfCurrentMembre } from '_stores/membranes-srnf/store'
import { NoteSchema } from '_stores/membranes/types'

interface MembraneNoteFormProps {
  onCancel?(): void 
  onSubmit?(): void 
  note?: NoteSchema
  srnf?: boolean
}

const MembraneNoteForm:React.FunctionComponent<MembraneNoteFormProps> = ({
  onCancel,
  onSubmit,
  note,
  srnf
}) => {
  const [currentMembrane, actions] = srnf ? useSrnfCurrentMembre() : useCurrentMembrane()

  return (
    <>
      <Form
        fields={ [
          {
            name: 'comment',
            label: 'Your comment',
            type: 'textarea',
            placeholder: 'Comment...'
          },
        ] }
        initialValues={ note }
        validate={ (values: any) => {
          const errors: any = {}
          if (!values.comment)
            errors.comment = 'Required field'
          return errors
        } }
        onSubmit={ (values: any) => {
          if (currentMembrane && currentMembrane._id) {
            if (note && note._id) {
              actions.updateNote({
                comment: values.comment,
                membraneId: currentMembrane._id,
                noteId: note._id
              })
            } else {
              actions.createNote({
                comment: values.comment,
                membraneId: currentMembrane._id
              })
            }
            onSubmit && onSubmit()
          } 
        } }
        cancelButton={ onCancel && { onClick: onCancel } }
        submitLabel='Submit'
      />
    </>

  ) }

export default MembraneNoteForm