import { MembraneSrnfSchema } from '../../../../backend/models/membrane-srnf.model'

export enum Types {
  commercial = 'Commercial',
  patent = 'Patent',
  labMade = 'Lab-made'
}

export enum Family {
  tfcTfn = 'Composite',
  isaInorganic = 'ISA/inorganic/dense',
  commercial = 'Commercial',
}


export enum Status {
  toValidate = 'To validate',
  detailsRequired = 'Details required',
  accepted = 'Accepted',
  rejected = 'Rejected'
}

export enum SupportLayerType {
  isa = 'ISA',
  commercial = 'Commercial',
  inorganic = 'Inorganic',
  fiber = 'fiber'

}

export enum IssueStatus {
  open = 'Open',
  inProgress = 'In progress',
  closed = 'Closed'
}
export enum Structure {
  isa = 'ISA',
  tfc = 'TFC',
  tfn = 'TFN',
  inorganic = 'Inorganic',
  dense = 'Dense',
  inorganicComposite = 'Inorganic Composite'
}


export type MembraneSrnf = MembraneSrnfSchema

