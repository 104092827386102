import * as React from 'react'
import Title from '_components/title'
import { useCurrentMembrane, useMembranes } from '_stores/membranes/store'
import MembraneForm from './form'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'
import { Button, Col, Row } from 'react-bootstrap'
import MembraneItemStatus from './includes/item-status'
import MembraneHistory from '_components/membranes/history'
import { useHistory } from 'react-router-dom'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSession } from '_stores/session/store'


const MembraneItem: React.FunctionComponent = () => {
  const [currentMembrane, actions] = useCurrentMembrane()
  const history = useHistory()
  const [state] = useMembranes()
  const [sessionState] = useSession()

  const [havePrevious, haveNext, indexOfCurrent] = React.useMemo(() => {
    const indexOfCurrent = currentMembrane && state.all.findIndex(m => m._id === currentMembrane._id)
    if (indexOfCurrent === -1)
      return [false, false, -1]

    return [indexOfCurrent !== 0, indexOfCurrent !== state.all.length - 1, indexOfCurrent]
  }, [state.all, currentMembrane])


  const naviguate = async (direction: 1 | -1) => {
    if (!indexOfCurrent && indexOfCurrent !== 0)
      return
    const membrane = state.all[indexOfCurrent + direction]
    if (!membrane || !membrane._id)
      return
    await actions.get(membrane._id)
    actions.setCurrent(membrane)
  }

  return (
    <PageApp>
      <ApiItemWrapper
        item={ currentMembrane }
        actions={ actions }
      >
        <Row>
          <Col md={ currentMembrane ? 8 : 12 }>
            <Title
              label={ currentMembrane ? 
                <div className='d-flex justify-content-start'>
                  <span className='mr-3'>Membrane: { currentMembrane.name }</span>
                  {
                    sessionState.user?.role === 'admin' && <MembraneItemStatus
                      membrane={ currentMembrane }
                      state={ state }
                      actions={ actions }
                    />
                  }
                  <div className='ml-2'>
                    <Button
                      size='sm'
                      onClick={ () => {
                        actions.setCurrent()
                        actions.setDuplicate(currentMembrane)
                        history.push('/membranes/new')
                      } }
                    >
                    Duplicate
                    </Button>
                  </div>
                  
                </div>
                : state.duplicate ? `New membrane (duplicate of ${state.duplicate.name})` : 'New membrane' }
            />
            <div>
              {currentMembrane && havePrevious && 
                <Button variant='link' size='sm' onClick={ () => naviguate(-1) }>
                  <FontAwesomeIcon icon={ faArrowLeft } className='mr-1'/>Previous
                </Button> 
              }
              {currentMembrane && haveNext && 
                <Button variant='link' size='sm' onClick={ () => naviguate(1) }>
                  Next<FontAwesomeIcon icon={ faArrowRight } className='ml-1'/>
                </Button>
              }
            </div>
            <div style={ { position: 'relative' } } className='mb-5'>
              <MembraneForm />
            </div>
          </Col>
          {
            currentMembrane && state.schema &&
              <Col md={ 4 }>
                <MembraneHistory 
                  membrane={ currentMembrane }
                  schema={ state.schema }
                />
              </Col>
          }
        </Row>
      </ApiItemWrapper>
    </PageApp>
  )
}

export default MembraneItem