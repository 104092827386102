import { createStore, createSubscriber, createHook, createContainer, StoreActionApi } from 'react-sweet-state'
import { genericActions, ResourceState } from '_core/store'
import { IssueStatus, MembraneSrnf, Status } from './types'
import { ClearRequestPayload } from '_core/store'
import history from '_core/history'

export interface MembranesSrnfState extends ResourceState<MembraneSrnf> {
  schema?: any
  filters: any
  duplicate?: Partial<MembraneSrnf>
}
export type MembranesSrnfApi = StoreActionApi<MembranesSrnfState>

const initialState: MembranesSrnfState = {
  endpoint: '/membranes-srnf',
  all: [],
  currentId: undefined,
  requests: {},
  schema: undefined,
  filters: {
    status: [Status.toValidate, Status.detailsRequired, Status.accepted]
  },
  duplicate: undefined,
  pagination: {
    pageSize: 50,
    currentPage: 1,
    total: 0,
    totalPages: 0
  }
}

const actions = {
  fetch: (query?: any) => async ({ dispatch, getState }: MembranesSrnfApi) => {
    if (!query)
      query = {}
    await dispatch(genericActions.apiList({
      ...query,
      limit: getState().pagination?.pageSize,
      pageSize: getState().pagination?.pageSize,
      page: getState().pagination?.currentPage,
      fields: ['name', '_id', 'dates', 'omdId', 'status', 'issues', 'report', 'family', 'creator', 'published'],
      populate: ['report', 'creator']
    }))
  },
  get: (_id: string) => async ({ dispatch }: MembranesSrnfApi) => {
    await dispatch(genericActions.apiGet(_id, {
      data: {
        populate: ['history.user', 'notes.user', 'report']
      }
    }))
  },
  create: (data: MembraneSrnf) => async ({ getState, dispatch }: MembranesSrnfApi) => {
    const res = await dispatch(genericActions.apiCreate(data))
    if (res && res.result)
      history.push(`${getState().endpoint}/${res.result._id}`)
  },
  update: (data: Partial<MembraneSrnf>) => async ({ dispatch }: MembranesSrnfApi) => {
    const res = await dispatch(genericActions.apiUpdate(data))
    if (res && res.result)
      await dispatch(actions.get(res.result._id))
  },
  createOrUpdate: (data: Partial<MembraneSrnf>) => async ({ dispatch }: MembranesSrnfApi) => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: MembranesSrnfApi) => {
    await dispatch(genericActions.apiDelete(_id))
  },
  createNote: (data: { membraneId: string, comment: string }) =>
    async ({ getState, dispatch }: MembranesSrnfApi) => {
      const res = await dispatch(genericActions.api({
        url: `${getState().endpoint}/${data.membraneId}/notes`,
        method: 'post',
        data
      }))
      if (res && res.result)
        dispatch(actions.get(res.result._id))
    },
  updateNote: (data: { membraneId: string, noteId: string, comment: string }) =>
    async ({ getState, dispatch }: MembranesSrnfApi) => {
      const res = await dispatch(genericActions.api({
        url: `${getState().endpoint}/${data.membraneId}/notes/${data.noteId}`,
        method: 'put',
        data
      }))
      if (res && res.result)
        dispatch(actions.get(res.result._id))
    },
  deleteNote: (data: { membraneId: string, noteId: string }) =>
    async ({ getState, dispatch }: MembranesSrnfApi) => {
      const res = await dispatch(genericActions.api({
        url: `${getState().endpoint}/${data.membraneId}/notes/${data.noteId}`,
        method: 'delete'
      }))
      if (res && res.result)
        dispatch(actions.get(res.result._id))
    },
  updateIssue: (data: { membraneId: string, issueId: string, status: IssueStatus }) =>
    async ({ getState, dispatch }: MembranesSrnfApi) => {
      const res = await dispatch(genericActions.api({
        url: `${getState().endpoint}/${data.membraneId}/issues/${data.issueId}`,
        method: 'put',
        data
      }))
      if (res && res.result)
        dispatch(actions.get(res.result._id))
    },
  getSchema: () => async ({ dispatch, getState, setState }: MembranesSrnfApi) => {
    const res = await dispatch(genericActions.api({
      method: 'get',
      url: `${getState().endpoint}/schema`
    }))
    setState({ schema: res.result })
  },
  setFilter: (name: string, value: any) => ({ getState, setState }: MembranesSrnfApi) => {
    if (!value) {
      const newFilters = { ...getState().filters }
      delete newFilters[name]
      setState({ filters: newFilters })
    } else {
      setState({
        filters: {
          ...getState().filters,
          [name]: value
        }
      })
    }
  },
  setDuplicate: (membrane?: MembraneSrnf) => ({ setState }: MembranesSrnfApi) => {
    if (!membrane)
      return setState({ duplicate: undefined })
    //const keysToDuplicate = ['name', 'type', 'published', 'structure', 'chemistry', 'modification', 'data', 'report', 'family']
    //const duplicate = keysToDuplicate.reduce((duplicate, key) => {
    //  duplicate[key] = membrane[key]
    //  return duplicate
    //}, {})
    const { _id, ...duplicate } = membrane
    setState({ duplicate })
  },
  setPagination: (paginationData: ResourceState<MembraneSrnf>['pagination']) => ({ setState }: MembranesSrnfApi) => {
    setState({ pagination: paginationData })
  },
  setCurrent: (membrane?: MembraneSrnf) => ({ dispatch }: MembranesSrnfApi) => {
    dispatch(genericActions.setCurrent(membrane))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: MembranesSrnfApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: MembranesSrnfApi) => {
    dispatch(genericActions.clearRequests())
  },
  getRequest: (method?: string, withId?: boolean) =>
    ({ dispatch }: MembranesSrnfApi) =>
      dispatch(genericActions.getRequest(method, withId)),
}

export type MembranesSrnfActions = typeof actions

const Store = createStore<MembranesSrnfState, MembranesSrnfActions>({
  name: 'membranesSrnf',
  initialState,
  actions
})

export const MembranesSrnfSubscriber = createSubscriber(Store)
export const useMembranesSrnf = createHook(Store)
export const useCurrentMembrane = createHook(Store, {
  selector: (state: MembranesSrnfState) => {
    if (state.currentId)
      return state.all.find(membrane => membrane._id === state.currentId)
    return
  }
})
export const MembranesSrnfContainer = createContainer(Store)

export default Store