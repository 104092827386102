import { CurrentMembraneSrnfFormValue, MakeSectionFields } from '_containers/membranes-srnf/form'
import { User } from '_stores/users/types'

const buildMembraneInformationsSectionFields = (
  makeSectionFields: MakeSectionFields,
  currentFormValue: CurrentMembraneSrnfFormValue,
  currentUser: User
): ReturnType<MakeSectionFields> | undefined => makeSectionFields('Membrane information', { md: 12 }, [
  { 
    path: 'name',
    colProps: { md: 12 },
    fieldPropsBuilder: (currentFormValue, field) => {
      field.type = 'input-group'
      field.group = {
        label: 'N/A',
        name: 'nameNA',
        mainType: 'text'
      }
    } }, 
  currentUser.role === 'admin' && { path: 'status', colProps: { md: 6 } },
  currentUser.role === 'admin' && { path: 'published', colProps: { md: 6 } }
].filter(v => v))

export default buildMembraneInformationsSectionFields