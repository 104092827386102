import * as React from 'react'
import Form from '_components/form'
import Loader from '_components/loader'
import { useCurrentMembrane, useMembranesSrnf } from '_stores/membranes-srnf/store'
import _, { get, isNaN, isObject, merge, set } from 'lodash'
import { FormFieldProp } from '_components/form/types'
import { useReports } from '_stores/reports/store'
import { TitleProps } from '_components/title'
import buildInterfacialPolymerizationSectionFields from '../../utils/membrane-form/srnf/section-fields/interfacial-polymerization'
import customValidations from '_utils/membrane-form/srnf/custom-validation'
import { MembraneSrnf, Status } from '_stores/membranes-srnf/types'
import buildMembraneTypesSectionFields from '_utils/membrane-form/srnf/section-fields/membrane-type'
import { buildTfnDataSectionFields } from '_utils/membrane-form/srnf/section-fields/tfn-data'
import buildSupportLayerSectionFields from '_utils/membrane-form/srnf/section-fields/support-layer'
import buildIsaSupportLayerSectionFields from '_utils/membrane-form/srnf/section-fields/isa-support-layer'
import buildPostTreatmentSectionFields from '_utils/membrane-form/srnf/section-fields/post-treatment'
import buildMembraneInformationsSectionFields from '_utils/membrane-form/srnf/section-fields/membrane-information'
import buildReportSectionFields from '_utils/membrane-form/srnf/section-fields/report'
import buildSoluteChoicesSectionFields from '_utils/membrane-form/srnf/section-fields/solute-choices'
import buildTestConditionsSectionFields from '_utils/membrane-form/srnf/section-fields/test-conditions'
import buildCharacterizationResultsSectionFields from '_utils/membrane-form/srnf/section-fields/characterization-results'
import buildFiltrationsResultsSectionFields from '_utils/membrane-form/srnf/section-fields/filtration-results'
import { validateReportInMembraneForm, validationFormSchema } from '_utils/membrane-form/validations'
import buildFieldPropsBySchema from '_utils/membrane-form/build-field-props-by-schema'
import { useSession } from '_stores/session/store'
import { User } from '_stores/users/types'

export type SectionFieldsPathOptions = { path: string, colProps?: any, fieldPropsBuilder?(currentFormValue: CurrentMembraneSrnfFormValue, field: FormFieldProp, validations: any): void }
export type SectionFieldsPath = string | SectionFieldsPathOptions

export type MakeSectionFields = (
  sectionTitle: string,
  colProps: any,
  fieldPaths: SectionFieldsPath[],
  sectionOptions?: { sectionTitleSize?: TitleProps['level'] }
) => FormFieldProp

export type CurrentMembraneSrnfFormValue = Partial<MembraneSrnf>

const MembraneForm: React.FunctionComponent = () => {
  const [currentMembrane, actions] = useCurrentMembrane()
  const [reportsState, reportsActions] = useReports()
  const [sessionState] = useSession()
  const [resetForm, setResetForm] = React.useState(false)
  const [showReportForm, setShowReportForm] = React.useState(false)
  const [state] = useMembranesSrnf()
  const [currentReport, setCurrentReport] = React.useState<any | undefined>(currentMembrane && currentMembrane.report)
  const [isUnsavedAlertActivated, setIsUnsavedAlertActivated] = React.useState(true)

  const [currentFormValue, setCurrentFormValues] = React.useState<CurrentMembraneSrnfFormValue>(currentMembrane || {})

  React.useEffect(() => {
    reportsActions.fetch()
    reportsActions.getSchema()
  }, [currentMembrane])

  //TODO: bugfix on form (not updated when initials values reseted)
  React.useEffect(() => {
    if (resetForm)
      setInterval(() => setResetForm(false), 1)
  }, [resetForm])

  React.useEffect(() => {
    if (!resetForm) {
      setResetForm(true)
      setCurrentFormValues(currentMembrane || state.duplicate || {})
    }
  }, [state.duplicate, state.currentId])


  React.useEffect(() => {
    setCurrentReport(currentMembrane && currentMembrane.report)
  }, [currentMembrane])

  const { fields, validations } = React.useMemo((): { fields: FormFieldProp[], validations: any } => {
    const validations: any = {}

    const validationsIfFieldsIsPresent = customValidations(currentFormValue)

    const makeSectionFields: MakeSectionFields = (sectionTitle, colProps, fieldPaths, sectionOptions) => {
      const fields: Partial<FormFieldProp>[] = []

      fieldPaths.forEach(path => {
        let fieldColProps = colProps
        let fieldPropsBuilder
        if (typeof path !== 'string') {
          fieldColProps = path.colProps
          fieldPropsBuilder = path.fieldPropsBuilder
          path = path.path
        }

        const schemaField = _.get(state.schema, path)
        if (schemaField)
          validations[path] = schemaField.validations
        if (schemaField && schemaField.validations && schemaField.validations.item)
          validations[path] = { ...schemaField.validations, ...schemaField.validations.item.validations }

        const label = schemaField && schemaField.labels ? schemaField.labels.short : _.upperFirst(path.replace('data.', ''))

        const field: any = {
          name: path,
          placeholder: `${label}...`,
          label: <span>{label} {schemaField && schemaField.labels && schemaField.labels.unit && <small>({schemaField.labels.unit})</small>}</span>,
          info: schemaField && schemaField.labels?.info,
          colProps: fieldColProps
        }

        fieldPropsBuilder ? fieldPropsBuilder(currentFormValue, field, validations) : buildFieldPropsBySchema(currentFormValue, schemaField, field, path)


        const customValidation = validationsIfFieldsIsPresent.find(i => i.path === path)
        if (customValidation)
          merge(get(validations, path), customValidation.rules)

        fields.push(field)
      })

      return {
        type: 'section',
        name: sectionTitle,
        section: {
          title: sectionTitle,
          titleSize: sectionOptions?.sectionTitleSize,
          fields: fields.map((field: any) => ({
            field,
            colProps: field.colProps
          }))
        }
      }
    }

    const allFields = !currentFormValue.family && !currentMembrane ? [makeSectionFields('', { md: 6 }, ['family'])] : [
      //REPORT
      buildReportSectionFields(makeSectionFields, currentFormValue, reportsState.all, reportsState.schema, currentReport, showReportForm),
      //MEMBRANE INFORMATION
      buildMembraneInformationsSectionFields(makeSectionFields, currentFormValue, sessionState.user as User),
      //TYPES
      buildMembraneTypesSectionFields(makeSectionFields, currentFormValue),
      //Interfacial Polymerization (phase 1 et 2)
      buildInterfacialPolymerizationSectionFields(makeSectionFields, currentFormValue, 1),
      buildInterfacialPolymerizationSectionFields(makeSectionFields, currentFormValue, 2),
      //TFN DATA
      buildTfnDataSectionFields(makeSectionFields, currentFormValue),
      //Support Layer
      buildSupportLayerSectionFields(makeSectionFields, currentFormValue),
      //ISA Support Layer
      buildIsaSupportLayerSectionFields(makeSectionFields, currentFormValue),
      //Membrane Post Treatment || Top Layer Post Treatment
      ...buildPostTreatmentSectionFields(
        makeSectionFields,
        currentFormValue,
        'postTreatment',
        'postTreatmentData',
        currentFormValue.family !== 'Composite' ? 'Membrane Post Treatment' : 'Top Layer Post Treatment',
        state.schema?.['interfacialPolymerization.additive1ConcentrationUnit'].item.validations.enum
      ),
      //Support Layer Post Treatment 
      ...buildPostTreatmentSectionFields(
        makeSectionFields,
        currentFormValue,
        'supportLayer.postTreatment',
        'supportLayer.postTreatmentData',
        'Support Layer Post Treatment',
        state.schema?.['interfacialPolymerization.additive1ConcentrationUnit'].item.validations.enum,
        true
      ),
      //Test conditions
      buildTestConditionsSectionFields(makeSectionFields, currentFormValue),
      //Characterization Results
      buildCharacterizationResultsSectionFields(makeSectionFields, currentFormValue),
      //Solutes
      ...buildSoluteChoicesSectionFields(makeSectionFields, currentFormValue),
      //Filtrations Results
      buildFiltrationsResultsSectionFields(makeSectionFields, currentFormValue),
      //Comment
      makeSectionFields('Comment', {}, ['comment']),
    ].filter(f => f)

    return {
      fields: state.schema && allFields,
      validations
    }
  }, [state.schema, reportsState.all, reportsState.schema, showReportForm, currentReport, currentMembrane, currentFormValue])

  const initialValues = React.useMemo(() => {
    const values = currentMembrane || state.duplicate
    return {
      ...values,
      soluteChoice: {
        ...(values?.soluteChoice || {}),
        multiple: values?.soluteChoice?.multiple || 1
      }
    }
  }, [currentMembrane, state.duplicate])

  if (!state.schema || !reportsState.schema || !reportsState.initialized || resetForm)
    return <Loader />

  return (
    <>
      <h3 className='mt-3'>{currentFormValue.family}</h3>
      <Form
        fields={ fields }
        unsavedAlert={ isUnsavedAlertActivated }
        initialValues={ initialValues }
        forceShowSubmit
        noSubmitOnEnter
        validate={ (values: any) => {
          const errors: any = { report: { author: {} } }
          validationFormSchema(errors, values, validations)
          validateReportInMembraneForm(errors, values, showReportForm)
          //Name validation (check N/A if empty)
          delete errors.name
          if (!values.name && !values.nameNA)
            errors.name = 'Select N/A if the name is not available'

          delete errors.undefined
          return errors
        } }
        onSubmit={ (v) => {
          const values = { ...v }

          if (sessionState.user?.role !== 'admin') {
            values.status = Status.toValidate
            values.published = false
          }
          if (typeof values.report.reference === 'object') {
            values.report = { ...v.report }
            values.report = values.report.reference.value
          } else if (values.report && values.report.publicationDate) {
            values.report = { ...v.report }
            values.report.publicationDate = new Date(values.report.publicationDate)
          }

          if (!values.supportLayerChemistry)
            values.supportLayerChemistry = 'Not Applicable'
          if (values.soluteChoice) {
            Object.keys(values.soluteChoice).forEach(soluteName => {
              if (values.soluteChoice[soluteName].name?.value)
                values.soluteChoice[soluteName].name = values.soluteChoice[soluteName].name.value
            })
          }
          const soluteChoiceMultiple = Number(values.soluteChoice.multiple)
          if (values.soluteChoice.multiple && !isNaN(soluteChoiceMultiple)) {
            const fantomValues = Array.from({ length: 15 - soluteChoiceMultiple }, (v, i) => soluteChoiceMultiple + i + 1)
            for (const index of fantomValues) {
              delete values.soluteChoice[`solute${index}`]
              delete values.filtrationResults[`rejection${index}`]
            }
          }

          if (typeof values.soluteChoice.multiple === 'number') {
            const multiple = values.soluteChoice.multiple
            const fantomValues = Array.from({ length: 15 - multiple }, (v, i) => multiple + i + 1)
            for (const index of fantomValues) {
              if (values.soluteChoice[`solute${index}`]) {
                delete values.soluteChoice[`solute${index}`]
              }
              if (values.filtrationResults && values.filtrationResults[`rejection${index}`]) {
                delete values.filtrationResults[`rejection${index}`]
              }
            }
          }

          const removeUglyFakeDataInUnit = (obj: any) => {
            for (const key in obj) {
              if (key.match('Unit') && Array.isArray(obj[key])) {
                obj[key] = obj[key].map(v => v.replace(/\$\$(.*)$/, ''))
              } else if (isObject(obj[key])) removeUglyFakeDataInUnit(obj[key])
            }
          }

          removeUglyFakeDataInUnit(values)

          setIsUnsavedAlertActivated(false)
          if (values._id)
            actions.update(values)
          else
            actions.create(values)
        } }
        onFieldChange={ (fieldName, value: any, setFieldValue, formValues) => {
          setCurrentFormValues(set({ ...currentFormValue }, fieldName, value))

          if (fieldName === 'supportLayer.postTreatment')
            setFieldValue(
              'supportLayer.postTreatmentData',
              (formValues?.supportLayer?.postTreatmentData || []).filter(v => value?.includes(v.postTreatment))
            )

          if (fieldName === 'postTreatment')
            setFieldValue(
              'postTreatmentData',
              (formValues?.postTreatmentData || []).filter(v => value?.includes(v.postTreatment))
            )

          if (fieldName === 'report.reference') {
            if (!value)
              setShowReportForm(false)
            else
              setShowReportForm(Boolean(typeof value === 'string'))
            if (!value || !value.value)
              setCurrentReport(undefined)
            else {
              const report = reportsState.all.find(i => i._id === value.value)
              setCurrentReport(report)
            }
          }
        } }
        request={ actions.getRequest() }
        submitLabel='Submit'
      />
    </>
  )
}

export default MembraneForm