import * as React from 'react'
import Form from '_components/form'
import Loader from '_components/loader'
import { useCurrentMembrane, useMembranes } from '_stores/membranes/store'
import _ from 'lodash'
import { FormFieldProp } from '_components/form/types'
import { useReports } from '_stores/reports/store'
import { Chemistry, Modification, Status, Structure } from '_stores/membranes/types'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useSession } from '_stores/session/store'

type SectionFieldsPath = string | { path: string, colProps: any }

const getOptionFromEnum = (enumValues: string[]): { value: string, label: string}[] =>
  enumValues && enumValues.map((value: any) => ({
    label: value,
    value
  }))
  

const MembraneForm:React.FunctionComponent = () => {
  const [currentMembrane, actions] = useCurrentMembrane()
  const [reportsState, reportsActions] = useReports()
  const [resetForm, setResetForm] = React.useState(false)
  const [sessionState] = useSession()
  const [showReportForm, setShowReportForm] = React.useState(false)
  const [state] = useMembranes()
  const [currentReport, setCurrentReport] = React.useState<any | undefined>(currentMembrane && currentMembrane.report)
  const [otherFields, setOhterFields] = React.useState<string[]>([])
  const [currentA, setCurrentA] = React.useState(currentMembrane && currentMembrane.data.a)
  const [currentB, setCurrentB] = React.useState(currentMembrane && currentMembrane.data.b)
  
  React.useEffect(() => {
    reportsActions.fetch()
    reportsActions.getSchema()
  }, [currentMembrane])

  //TODO: bugfix on form (not updated when initials values reseted)
  React.useEffect(() => {
    if (resetForm)
      setInterval(() => setResetForm(false), 1)
  }, [resetForm])

  React.useEffect(() => {
    if (!resetForm)
      setResetForm(true)
  }, [state.duplicate, state.currentId])

  React.useEffect(() => {
    setCurrentReport(currentMembrane && currentMembrane.report)
    if (currentMembrane) {
      const others = []
      if (currentMembrane.structure.includes(Structure.other))
        others.push('structure')
      if (currentMembrane.chemistry.includes(Chemistry.other))
        others.push('chemistry')
      if (currentMembrane.modification.includes(Modification.other))
        others.push('modification')
      if (currentMembrane.data.otherSolutes)
        others.push('data.otherSolutes')
      if (others[0])
        setOhterFields(others)
    }
  }, [currentMembrane])

  const { fields, validations } = React.useMemo((): { fields: FormFieldProp[], validations: any } => {
    const validations: any = {}

    const makeSectionFields = (sectionTitle: string, colProps: any, fieldPaths: SectionFieldsPath[]): FormFieldProp => {
      const fields: Partial<FormFieldProp>[] = []
      fieldPaths.forEach(path => {
        let fieldColProps = colProps
        if (typeof path !== 'string') {
          fieldColProps = path.colProps
          path = path.path
        }
        const schemaField = _.get(state.schema, path)
        if (schemaField)
          validations[path] = schemaField.validations
        if (schemaField && schemaField.validations && schemaField.validations.item)
          validations[path] = { ...schemaField.validations, ...schemaField.validations.item.validations }

        const label = schemaField && schemaField.labels ? schemaField.labels.short : _.upperFirst(path.replace('data.', ''))

        const field: any = {
          name: path,
          placeholder: `${label}...`,
          label: <span>{ label } { schemaField && schemaField.labels && schemaField.labels.unit && <small>({schemaField.labels.unit})</small>}</span>,
          colProps: fieldColProps
        }
        
        if (path === 'name') {
          field.type = 'input-group'
          field.group = {
            label: 'N/A',
            name: 'nameNA',
            mainType: 'text'
          }
        } else if (path === 'data.cp') {
          field.type = 'input-group'
          field.group = {
            label: 'N/A',
            name: 'dataCPNA',
            mainType: 'number'
          }
        } else if (path === 'report.reference') {
          if (!showReportForm) {
            field.type = 'creatable-select'
            field.label = 'Report reference (DOI, product datasheet reference, patent number)'
            field.options = reportsState.all.map(report => ({
              label: report.reference,
              value: report._id
            }))
            field.info = currentReport && currentReport._id && 
            <span>
              <OverlayTrigger
                trigger='focus' 
                placement={ 'bottom' }
                overlay={
                  <Popover id={ 'popover-positioned-bottom' } style={ { maxWidth: 400 } }>
                    <Popover.Title as='h3'>{ currentReport.reference }</Popover.Title>
                    <Popover.Content>
                      <strong>{ currentReport.type }</strong>&nbsp;
                      by { currentReport.author && currentReport.author.lastName } ({currentReport.publicationDate && new Date(currentReport.publicationDate).getFullYear()})<br /><br />
                      { currentReport.title }<br /><br />
                      { currentReport.link && <a href={ currentReport.link } target='__blank'>{ currentReport.link }</a> }
                    </Popover.Content>
                  </Popover>
                }
              >
                <span>                
                  <Button variant='link' className='p-0 mr-1'>
                    <FontAwesomeIcon icon={ faInfoCircle }/>
                  </Button>
                </span>
              </OverlayTrigger>
              { currentReport.link && <a href={ currentReport.link } target='__blank'>{ currentReport.link }</a> }
            </span>
            
            
            currentReport && currentReport.link ?
              <a href={ currentReport.link } target='__blank'>{ currentReport.link }</a>
              : 'Select option or write a report\'s reference to create it'
          } else {
            field.type = 'text'
            field.label = 'Reference'
          }
        } else if (path === 'report.type') {
          field.type = 'select'
          field.label = 'Type'
          field.placeholder = 'Type...'
          field.options = reportsState.schema && getOptionFromEnum(reportsState.schema.type.validations.enum)
        } else if (path.match('report.')) {
          if (path === 'report.publicationDate') {
            field.label = 'Year of publication'
          } else if (path === 'report.author.lastName')
            field.label = 'Author last name'
          else 
            field.label = _.startCase(path.replace('report.', '').replace('author.', ''))
          if (path === 'report.publicationDate')
            field.placeholder = '2021...'
          else
            field.placeholder = `${field.label}...`
        } else if (path === 'report.publicationDate') {
          field.type = 'number'
          field.min = 1900
          field.max = new Date().getFullYear()
        } else if (schemaField && (schemaField.type === 'String' || path === 'structure')) {
          let fieldEnum = schemaField.validations.enum
          if (path === 'structure')
            fieldEnum = schemaField.item.validations.enum
          if (fieldEnum) {
            field.type = 'select'
            field.options = getOptionFromEnum(fieldEnum)
          } else {
            field.type = 'text'
          }
        } else if (schemaField && schemaField.type === 'Number') {
          field.type = 'number'
          field.min = schemaField.validations.min
          field.max = schemaField.validations.max
        } else if (schemaField && schemaField.type === 'Boolean') {
          field.type = 'checkbox'
          field.options = [{ label: 'Yes', value: true }]
          field.inline = true
        }else if (schemaField && schemaField.type === 'Array') {
          field.type = 'react-select'
          field.options = getOptionFromEnum(schemaField.item.validations.enum)
          field.multiple = true
          field.inline = true
          field.select = true
        }
        fields.push(field)
      })
      return {
        type: 'section',
        name: sectionTitle,
        section: {
          title: sectionTitle,
          fields: fields.map((field: any) => ({
            field,
            colProps: field.colProps
          }))
        }
      }
    }

    const reportFieldsNames = ['report.reference']
    
    if (showReportForm) {
      reportFieldsNames.push('report.title')
      reportFieldsNames.push('report.type')
      reportFieldsNames.push('report.author.lastName')
      reportFieldsNames.push('report.publicationDate')
    }

    const membranesTypes = ['structure', 'chemistry', 'modification']
    
    if (otherFields.includes('structure'))
      membranesTypes.splice(membranesTypes.indexOf('structure') + 1, 0, 'structureOther')
    if (otherFields.includes('chemistry'))
      membranesTypes.splice(membranesTypes.indexOf('chemistry') + 1, 0, 'chemistryOther')
    if (otherFields.includes('modification'))
      membranesTypes.push('modificationOther')

    const testConditionsFields: any[] = ['data.filtrationMode', 'data.hydraulicP', 'data.naClConcM', 'data.osmoticP', 'data.cp', 'data.cpMode', 'data.ph', 'data.couponSize', 'data.otherSolutes']
    if (otherFields.includes('data.otherSolutes'))
      testConditionsFields.push({ path: 'data.otherSolutesDetails', colProps: { md: 6 } })

    const infosFields = ['name', sessionState.user?.role === 'admin' && 'status', sessionState.user?.role === 'admin' && { path: 'published', colProps: { md: 6 } }/*, { path: 'suspicious', colProps: { md: 3 } }*/].filter(v => v)
    
      
    const allFields = [
      makeSectionFields('Report', { md: showReportForm ? 6 : 12 }, reportFieldsNames),
      makeSectionFields('Membrane information', { md: 6 }, infosFields),
      makeSectionFields('', { md: 12 }, membranesTypes),
      makeSectionFields('Test information', { md: 3 }, ['data.a', 'data.b', 'data.ab', 'data.rreal', 'data.robs', 'data.pw', 'data.ps']),
      makeSectionFields('Test conditions', { md: 3 }, testConditionsFields),
      makeSectionFields('Membrane characterization', { md: 3 }, ['data.thickness', 'data.roughness', 'data.ca']),
      makeSectionFields('', { md: 12 }, ['data.characterization'])
    ]

    return {
      fields: state.schema && allFields,
      validations
    }
  }, [state.schema, reportsState.all, reportsState.schema, showReportForm, currentReport, otherFields, currentMembrane])

  const initialValues = React.useMemo(() => {
    const values = currentMembrane || state.duplicate
    return {
      ...values,
      report: values && values.report && values.report._id ? {
        _id: values.report._id,
        reference: values.report.reference,
        publicationDate: values.report.publicationDate ? new Date(values.report.publicationDate).getFullYear() : undefined
      } : {},
      status: values && values.status ? values.status : Status.toValidate,
      data: values && values.data ? { ...values.data } : { otherSolutes: false },
      nameNA: values && (!values.name || values.name === 'N/A') ? true : false,
      dataCPNA: values && values.data && !values.data.cp ? true : false
    }
  }, [currentMembrane, state.duplicate])

  if (!state.schema || !reportsState.schema || !reportsState.initialized || resetForm)
    return <Loader />
    
  return (
    <Form
      fields={ fields }
      unsavedAlert
      initialValues={ initialValues }
      forceShowSubmit
      noSubmitOnEnter
      validate={ (values: any) => {
        const errors: any = { report: { author: {} } }
        for (const key in validations) {
          const value = _.get(values, key)
          const validation = validations[key] || {}
          if (validation && validation.required && !value && value !== false && value !== 0) {
            _.set(errors, key, 'Required field')
          } else if (
            (validation.min && value < validation.min) ||
              (validation.max && value > validation.max)
          ) {
            if (validation.min !== undefined && validation.max !== undefined)
              _.set(errors, key, `The value must be between ${validation.min} and ${validation.max}`)
            else if (validation.min !== undefined)
              _.set(errors, key, `The value must be greater than ${validation.min}`)
            else
              _.set(errors, key, `The value must be smaller than ${validation.max}`)
          } else if (validation.minLength && (!value || !value[0])) {
            _.set(errors, key, `At least ${validation.minLength} ${ validation.minLength === 1 ? 'element' : 'elements'} must be selected`)
          }
        }
        //if (values.structure && values.structure.includes(Structure.hybrid) && (!values.chemistry || !values.chemistry[1]))
        //  errors.chemistry = 'At least 2 chemistries are required for the "TFN / Hybrid" structure'
        if (!values.report || !values.report.reference) {
          errors.report.reference = 'Required field'
        } else if (values.report && !values.report._id && !values.report.reference.value) {
          if (!values.report.title)
            errors.report.title = 'Required field'
          if (!values.report.publicationDate)
            errors.report.publicationDate = 'Required field'
          if (!values.report.author || !values.report.author.lastName)
            errors.report.author.lastName = 'Required field'
        }
        if (showReportForm && (!values.report || !values.report.type)) {
          errors.report.type = 'Required field'
        }

        if (errors.structure) {
          errors.structure = 'Required field'
        }

        if (
          showReportForm && (
            !values.report ||
            !values.report.publicationDate || 
            !Boolean(Number(values.report.publicationDate)) ||
            Number(values.report.publicationDate) > new Date().getFullYear() ||
            Number(values.report.publicationDate) < 1000
          )
        ) {
          errors.report.publicationDate = 'Please enter the year of publication (ex: 2021)'
        }

        if (!values.data.cp && !values.dataCPNA)
          _.set(errors, 'data.cp', 'Select N/A if you don\'t know the value')

        delete errors.name
        if (!values.name && !values.nameNA) {
          errors.name = 'Select N/A  if the name is not available'
        }

        if (!Object.keys(errors.report.author)[0])
          delete errors.report.author
        if (!Object.keys(errors.report)[0])
          delete errors.report
        return errors
      } }
      onSubmit={ (v) => {
        const values = { ...v }
        if (sessionState.user?.role !== 'admin') {
          values.status = Status.toValidate
          values.published = false
        }
        if (typeof values.report.reference === 'object') {
          values.report = { ...v.report }
          values.report = values.report.reference.value
        } else if (values.report && values.report.publicationDate) {
          values.report = { ...v.report }
          values.report.publicationDate = new Date(values.report.publicationDate)
        }
        if (values._id)
          actions.update(values)
        else
          actions.create(values)
      } }
      onFieldChange={ (fieldName, value: any, setFieldValue) => {
        if (fieldName === 'structure' || fieldName === 'chemistry' || fieldName === 'modification' || fieldName === 'data.otherSolutes') {
          if (value === 'Other' || (value && value.includes && value.includes('Other')) || (fieldName === 'data.otherSolutes' && value)) {
            const existing = otherFields.some(f => f === fieldName)
            if (!existing)
              setOhterFields([...otherFields, fieldName])
          } else {
            setOhterFields([...otherFields.filter(f => f !== fieldName)])
          }
        }

        if (fieldName === 'data.a') {
          setCurrentA(value)
          if (currentB)
            setFieldValue('data.ab', value / currentB)
        }
        if (fieldName === 'data.b') {
          setCurrentB(value)
          if (currentA)
            setFieldValue('data.ab', currentA / value)
        }

        if (fieldName === 'report.reference') {
          if (!value)
            setShowReportForm(false)
          else
            setShowReportForm(Boolean(typeof value === 'string'))
          if (!value || !value.value)
            setCurrentReport(undefined)
          else {
            const report = reportsState.all.find(i => i._id === value.value)
            setCurrentReport(report)
          }
        }
      } }
      request={ actions.getRequest() }
      submitLabel='Submit'
    />
  ) }

export default MembraneForm