import { CurrentMembraneSrnfFormValue, MakeSectionFields, SectionFieldsPathOptions } from '_containers/membranes-srnf/form'
import { Structure, Family, SupportLayerType } from '_stores/membranes-srnf/types'
import { hasStructure } from '_stores/membranes-srnf/utils'



const isDisplayed = (currentFormValue: CurrentMembraneSrnfFormValue) => 
  ((currentFormValue.family === 'Composite' && currentFormValue.supportLayerType === 'ISA') ||
  (currentFormValue.family === 'ISA/inorganic/dense' && currentFormValue.structure &&
  (hasStructure(currentFormValue.structure, Structure.isa) || hasStructure(currentFormValue.structure, Structure.dense))))

const buildIsaSupportLayerSectionFields = (
  makeSectionFields: MakeSectionFields,
  currentFormValue: CurrentMembraneSrnfFormValue
): ReturnType<MakeSectionFields> | undefined => {
  if (!isDisplayed(currentFormValue)) return

  const isIsaStructure = currentFormValue.structure?.includes(Structure.isa)

  const isIsaSupportLayerTypeAndCompositeFamily = currentFormValue.family === Family.tfcTfn && currentFormValue.supportLayerType === SupportLayerType.isa

  let fields: SectionFieldsPathOptions[] = [
    { path: 'isaSupportLayer.polymerWeightConcentrationInDopeSolution', colProps: { md: 6 } },
    { path: 'isaSupportLayer.solvent', colProps: { md: 6 } }
  ]

  if (currentFormValue?.isaSupportLayer?.solvent && currentFormValue.isaSupportLayer.solvent.length > 1)
    fields = [...fields, { path: 'isaSupportLayer.solventRatio', colProps: { md: 6 } }]

  fields = [
    ...fields,
    (isIsaStructure || isIsaSupportLayerTypeAndCompositeFamily) && { path: 'isaSupportLayer.nonSolvent', colProps: { md: 6 } },
    { path: 'isaSupportLayer.additivesInDopeSolutionSmiles', colProps: { md: 6 } },
    { path: 'isaSupportLayer.dopeSolutionAdditiveConcentration', colProps: { md: 6 } },
    { path: 'isaSupportLayer.dopeSolutionAdditiveConcentrationUnit', colProps: { md: 6 },
    },
    isIsaStructure && { path: 'isaSupportLayer.additiveInCoagulationMediumSmiles', colProps: { md: 6 } },
    isIsaStructure && { path: 'isaSupportLayer.coagulationMediumAdditivesConcentration', colProps: { md: 6 } },
    isIsaStructure && { path: 'isaSupportLayer.coagulationMediumAdditivesConcentrationUnit', colProps: { md: 6 } },
    { path: 'isaSupportLayer.castingThickness', colProps: { md: 6 } }
  ].filter(v => v)

  return makeSectionFields('', {}, fields)
}

export default buildIsaSupportLayerSectionFields