import { FormFieldProp } from '_components/form/types'
import { getOptionFromEnum } from './common'
import { CurrentMembraneSrnfFormValue } from '_containers/membranes-srnf/form'
import { SelectOptions } from '_components/form/fields/select'

const buildFieldPropsBySchema = (currentFormValue: CurrentMembraneSrnfFormValue, schemaField: any, field: FormFieldProp, path: string) => {
  if (schemaField && (schemaField.type === 'String' || path === 'structure')) {
    const fieldEnum = schemaField.validations?.enum || schemaField.item?.validations?.enum
    if (fieldEnum) {
      field.type = 'select'
      field.options = getOptionFromEnum(fieldEnum)
      if (path === 'type' && (currentFormValue.family === 'Composite' || currentFormValue.family === 'ISA/inorganic/dense'))
        field.options = field.options.filter((option: SelectOptions) => option.value === 'Scientific report' || option.value === 'Patent')

      if (path === 'structure' && currentFormValue.family === 'Composite')
      
        field.options = field.options.filter((option: SelectOptions) => option.value === 'TFC' || option.value === 'TFN' || option.value === 'Inorganic Composite')

      if (path === 'structure' && currentFormValue.family === 'ISA/inorganic/dense')
        field.options = field.options.filter((option: SelectOptions) => option.value === 'ISA' || option.value === 'Inorganic' || option.value === 'Dense') 
    } else {
      field.type = 'text'
    }
  } else if (schemaField && schemaField.type === 'Number') {
    field.type = 'number'
    field.min = schemaField.validations.min
    field.max = schemaField.validations.max
  } else if (schemaField && schemaField.type === 'Boolean') {
    field.type = 'checkbox'
    field.options = [{ label: 'Yes', value: true }]
    field.inline = true
  } else if (schemaField && schemaField.type === 'Array') {
    field.type = 'react-select'
    field.options = getOptionFromEnum(schemaField.item.validations.enum)
    field.multiple = true
    field.inline = true
    field.select = true
    field.canMultiSelectSameValue = Boolean(field.name.match('Unit'))
  }
}

export default buildFieldPropsBySchema