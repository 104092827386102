import { CurrentMembraneSrnfFormValue, MakeSectionFields } from '_containers/membranes-srnf/form'

const buildTestConditionsSectionFields = (
  makeSectionFields: MakeSectionFields,
  currentFormValue: CurrentMembraneSrnfFormValue
): ReturnType<MakeSectionFields> => {
  const haveSolventMixture = currentFormValue.testConditions?.solventMixture
  const fields = [
    'testConditions.filtrationMode',
    'testConditions.hydraulicP',
    'testConditions.temperature',
    'testConditions.couponSize', 
    'testConditions.solventMixture', 
    'testConditions.solvent1'
  ]

  if (haveSolventMixture) {
    fields.push('testConditions.solvent2')
    fields.push('testConditions.solventRatio')
  }

  return makeSectionFields('Test conditions', { md: 3 }, fields)
}

export default buildTestConditionsSectionFields