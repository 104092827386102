import { useSession } from '_stores/session/store'
import React from 'react'

interface ProtectedProps {
  roles: ('user' | 'admin')[]
}

export const isAuthorizedFor = (roles: ProtectedProps['roles']): boolean => {
  const [state] = useSession()
  return Boolean(state.user?.role && roles.includes(state.user?.role))
}

export const isProtectedFor = (roless: ProtectedProps['roles']): boolean => !isAuthorizedFor(roless)

const Protected: React.FC<ProtectedProps> = ({ roles, children }) => (
  <>{isAuthorizedFor(roles) && children}</>
)

export default Protected
