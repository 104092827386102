import { CurrentMembraneSrnfFormValue, MakeSectionFields } from '_containers/membranes-srnf/form'

const buildFiltrationsResultsSectionFields = (
  makeSectionFields: MakeSectionFields,
  currentFormValue: CurrentMembraneSrnfFormValue,
): ReturnType<MakeSectionFields> | undefined => {
  let soluteCount = 1
    
  if (currentFormValue.soluteChoice?.multiple) {
    let value = Number(currentFormValue.soluteChoice?.multiple)
    if (value > 15) value = 15
    if (value < 1) value = 1
    soluteCount = value
  } 

  return makeSectionFields('Filtration Performance', { md: 4 }, [
    'filtrationResults.permeance',
    'filtrationResults.solventPermeance',
    'filtrationResults.mwco',
    ...[...Array(Number(soluteCount)).keys()].map(i => 
      `filtrationResults.rejection${i + 1}`),
  ])
}

export default buildFiltrationsResultsSectionFields