import { createStore, createSubscriber, createHook, createContainer, StoreActionApi } from 'react-sweet-state'
import { User, PasswordRecoveryPayload, ResetPasswordPayload, InvitePayload, CreatePayload, CompleteProfilePayload, UpdateUserPayload, ResendInvitationPayload, AccountValidationPayload } from './types'
import { ClearRequestPayload, genericActions, ResourceState } from '_core/store'

export interface UsersState extends ResourceState<User> {
  all: User[]
  currentId?: string
  resetPasswordToken?: string
}
export type UsersApi = StoreActionApi<UsersState>

const initialState: UsersState = {
  endpoint: '/users',
  all: [],
  currentId: undefined,
  requests: {},
  resetPasswordToken: undefined
}

const actions = {
  fetch: () => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.apiFetch())
  },
  get: (_id: string) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.apiGet(_id))
  },
  create: (data: CreatePayload) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.apiCreate(data))
  },
  update: (data: UpdateUserPayload) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.apiUpdate(data))
  },
  createOrUpdate: (data: CreatePayload | UpdateUserPayload) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.apiDelete(_id))
  },
  setCurrent: (user?: User) => ({ dispatch }: UsersApi) => {
    dispatch(genericActions.setCurrent(user))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: UsersApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: UsersApi) => {
    dispatch(genericActions.clearRequests())
  },
  getRequest: (method?: string, withId?: boolean) =>
    ({ dispatch }: UsersApi) =>
      dispatch(genericActions.getRequest(method, withId)),
  invite: (data: InvitePayload) => async ({ setState, getState, dispatch }: UsersApi) => {
    const res = await dispatch(genericActions.api({
      url: '/users/invite',
      method: 'post',
      data
    }))
    if (res && res.result) {
      setState({
        all: [...getState().all, res.result]
      })
    }
  },
  resendInvitation: (data: ResendInvitationPayload) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.api({
      url: `/users/${data.id}/resend-invitation`,
      method: 'post'
    }))
  },
  passwordRecovery: (data: PasswordRecoveryPayload) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.api({
      url: '/users/password-recovery',
      method: 'post',
      data
    }))
  },
  resetPassword: (data: ResetPasswordPayload) => async ({ setState, dispatch }: UsersApi) => {
    const res = await dispatch(genericActions.api({
      url: '/users/reset-password',
      method: 'post',
      data
    }))
    if (res && res.result && res.result.token)
      setState({
        resetPasswordToken: res.result.token
      })
  },
  completeProfile: (data: CompleteProfilePayload) => async ({ dispatch }: UsersApi) => {
    const res = await dispatch(genericActions.api({
      url: '/users/complete-profile',
      method: 'post',
      data
    }))
    if (res && res.result && res.result.token)
      location.replace(`/is-auth?token=${res.result.token}`)
  },
  accountValidation: (data: AccountValidationPayload) => async ({ dispatch }: UsersApi) => {
    const res = await dispatch(genericActions.api({
      url: '/users/account-validation',
      method: 'get',
      data
    }))

    return res
  }
}

export type UsersActions = typeof actions

const Store = createStore<UsersState, UsersActions>({
  name: 'users',
  initialState,
  actions
})

export const UsersSubscriber = createSubscriber(Store)
export const useUsers = createHook(Store)
export const useCurrentUser = createHook(Store, {
  selector: (state: UsersState) => {
    if (state.currentId)
      return state.all.find(user => user._id === state.currentId)
    return
  }
})
export const UsersContainer = createContainer(Store)

export default Store