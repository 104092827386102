import { get, isArray, set } from 'lodash'

const setError = (errors: any, key: string, message: string) => {
  key && set(errors, key, message)
}

export const validationFormSchema = (errors: any, values: any, validations: any) => {
  for (const key in validations) {
    const value = get(values, key)
    const validation = validations[key] || {}
    if (validation && validation.required && isArray(value) && (!value || !value[0])) {
      setError(errors, key, 'Required field')

    } else if (validation && validation.required && !value && value !== false && value !== 0) {
      setError(errors, key, 'Required field')
    } else if (
      (validation.min && value < validation.min) ||
      (validation.max && value > validation.max)
    ) {
      if (validation.min !== undefined && validation.max !== undefined)
        setError(errors, key, `The value must be between ${validation.min} and ${validation.max}`)
      else if (validation.min !== undefined)
        setError(errors, key, `The value must be greater than ${validation.min}`)
      else
        setError(errors, key, `The value must be smaller than ${validation.max}`)
    } else if (validation.minLength && (!value || !value[0])) {
      setError(errors, key, `At least ${validation.minLength} ${validation.minLength === 1 ? 'element' : 'elements'} must be selected`)
    } else if (validation.ratio) {
      const error = validateRatio(value, validation.ratio)
      if (error)
        setError(errors, key, error)
    }
  }
}

export const validateReportInMembraneForm = (errors: any, values: any, isFormDisplayed: boolean) => {
  if (!values.report || !values.report.reference) {
    errors.report.reference = 'Required field'
  } else if (values.report && !values.report._id && !values.report.reference.value) {
    if (!values.report.title)
      errors.report.title = 'Required field'
    if (!values.report.publicationDate)
      errors.report.publicationDate = 'Required field'
    if (!values.report.author || !values.report.author.lastName)
      errors.report.author.lastName = 'Required field'
  }

  if (isFormDisplayed && (!values.report || !values.report.type)) {
    errors.report.type = 'Required field'
  }

  if (
    isFormDisplayed && (
      !values.report ||
      !values.report.publicationDate ||
      !Boolean(Number(values.report.publicationDate)) ||
      Number(values.report.publicationDate) > new Date().getFullYear() ||
      Number(values.report.publicationDate) < 1000
    )
  ) {
    errors.report.publicationDate = 'Please enter the year of publication (ex: 2021)'
  }

  if (!Object.keys(errors.report.author)[0])
    delete errors.report.author
  if (!Object.keys(errors.report)[0])
    delete errors.report
}


const validateRatio = (value?: string, expectedValues?: number): string | undefined => {
  if (!value) return 'required field'
  try {
    const values = value.split(':')
    if (expectedValues && values.length !== expectedValues)
      return `Need ${expectedValues} ratio values`
    let total = 0
    values.forEach((value) => {
      if (isNaN(Number(value))) throw 'Invalid value'
      total += Number(value)
    })
    if (total !== 100)
      return 'The sum of both XX and YY should amount to 100'
  } catch (err) {
    return 'Invalid value. Must be XX:YY (e.g., "80:20", "70:30", "20:20:60")'
  }
  return
}