import * as React from 'react'
import Title from '_components/title'
import Table from '_components/table'
import { Link, useHistory } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import moment from 'moment'
import Status from '_components/status'
import { getIssueStatusIcon, getStatusVariant } from '_stores/membranes-srnf/utils'
import Form, { FieldsProps } from '_components/form'
import { Status as StatusEnum } from '_stores/membranes-srnf/types'
import qs from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import useDidMountEffect from '_core/hooks'
import { useMembranesSrnf } from '_stores/membranes-srnf/store'
import { useSession } from '_stores/session/store'
import UserName from '_components/user-name'
import { useUsers } from '_stores/users/store'
import { User } from '_stores/users/types'

const MembranesSrnfList: React.FunctionComponent = () => {
  const [state, actions] = useMembranesSrnf()
  const [sessionState] = useSession()
  const history = useHistory()
  const request = state.requests['get /membranes-srnf/pages']
  const [usersState, usersActions] = useUsers()


  const [searchValue, setSearchValue] = React.useState(0)

  const lastRender = Date.now()

  React.useEffect(() => {
    const now = Date.now()
    const timeout = setTimeout(() => {
      if (now >= lastRender) {
        actions.setFilter('search', searchValue)
      }
    }, 300)
    return () => clearTimeout(timeout)
  }, [searchValue])

  useDidMountEffect(() => {
    if (state.pagination?.currentPage === 1)
      actions.fetch(state.filters)
    else
      actions.setPagination({ ...state.pagination, currentPage: 1 })
  }, [state.filters])

  useDidMountEffect(() => {
    actions.fetch(state.filters)
  }, [state.pagination?.currentPage, state?.pagination?.pageSize])

  React.useEffect(() => {
    actions.getSchema()
    usersActions.fetch()
    if (!state.all[1])
      actions.fetch(state.filters)
  }, [])

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'omdId',
        width: '5%',
        Cell: (data: any) => <div className='text-center'>{data.cell.row.original.omdId}</div>
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (data: any) =>
          <Link to={ `/membranes-srnf/${data.cell.row.original._id}` }>{data.value}</Link>
      },
      {
        Header: 'Family',
        accessor: 'family',
      },
      {
        Header: 'Report',
        accessor: 'report.reference',
        Cell: (data: any) =>
          <span>
            {data.cell.row.original.report && data.cell.row.original.report.reference}
          </span>
      },
      {
        Header: 'Report Author',
        accessor: 'report.author',
        Cell: (data: any) =>
          <span>
            {data.cell.row.original.report && data.cell.row.original.report.author.lastName}
          </span>
      },
      sessionState.user?.role === 'admin' &&
      {
        Header: 'By',
        accessor: 'creator',
        width: '15%',
        Cell: (data: any) => <UserName user={ data.cell.row.original.creator } />
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '15%',
        Cell: (data: any) => <Status status={ data.value } variant={ getStatusVariant(data.value) } />
      },
      {
        Header: 'Published',
        accessor: 'published',
        width: '10%',
        Cell: (data: any) => data.cell.row.original?.published === true ? 'Yes' : 'No'
      },
      {
        Header: 'Issues',
        disableSortBy: true,
        accessor: (d: any) => {
          const issuesStatus = {}
          d.issues && d.issues.forEach((issue: any) => {
            if (!issuesStatus[issue.status])
              issuesStatus[issue.status] = 1
            else
              issuesStatus[issue.status] += 1
          })
          return Object.keys(issuesStatus).sort().map((status) =>
            <span className='mr-1' key={ status }>
              <Status
                status={ issuesStatus[status] }
                icon={
                  <FontAwesomeIcon
                    icon={ getIssueStatusIcon(status) }
                  />
                }
              />

            </span>
          )
        },
        width: '5%'
      },
      {
        Header: 'Date',
        width: '15%',
        accessor: 'dates.created',
        Cell: (data: any) => <span>{moment(data.value).format()}</span>,
      },
    ].filter(v => v),
    []
  )

  const filtersFields: FieldsProps = React.useMemo((): FieldsProps => [
    {
      type: 'section',
      name: 'filters',
      section: {
        fields: [
          {
            field: {
              label: 'Search',
              name: 'search',
              type: 'text'
            },
            colProps: {
              lg: 4,
              md: 6
            }
          },
          {
            field: {
              label: 'Family',
              name: 'family',
              type: 'react-select',
              multiple: true,
              options: state.schema ? state.schema.family?.validations.enum.map((value: StatusEnum) => ({
                label: value,
                value
              })) : []
            },
            colProps: {
              lg: 4,
              md: 6
            }
          },
          {
            field: {
              label: 'Status',
              name: 'status',
              type: 'react-select',
              multiple: true,
              options: state.schema ? state.schema.status?.validations.enum.map((value: StatusEnum) => ({
                label: <Status status={ value } variant={ getStatusVariant(value) } />,
                value
              })) : []
            },
            colProps: {
              lg: 4,
              md: 6
            }
          },
          {
            field: {
              label: 'Published on the OMD ?',
              name: 'published',
              type: 'checkbox',
              options: [
                {
                  value: true,
                  label: 'Yes'
                }
              ]
            },
            colProps: {
              lg: 2,
              md: 3
            }
          },
          {
            field: {
              label: 'Imported from OSN',
              name: 'osn',
              type: 'checkbox',
              options: [
                {
                  value: true,
                  label: 'Yes'
                }
              ]
            },
            colProps: {
              lg: 2,
              md: 3
            }
          },
          sessionState.user?.role === 'admin' && {
            field: {
              label: 'Creators',
              name: 'creator',
              type: 'react-select',
              multiple: true,
              options: usersState.all ? usersState.all.filter((user: User) => user.firstName).map((user: User) => ({
                label: `${user.firstName} ${user.lastName}`,
                value: user._id
              })) : []
            },
            colProps: {
              lg: 4,
              md: 6
            }
          },
          sessionState.user?.role === 'admin' && {
            field: {
              label: 'Issues',
              name: 'issues.status',
              type: 'react-select',
              multiple: true,
              options: state.schema ? state.schema.issues.item.status.validations.enum.map((value: StatusEnum) => ({
                label: <Status
                  status={ value }
                  icon={
                    <FontAwesomeIcon
                      icon={ getIssueStatusIcon(value) }
                    //className={ `text-${getStatusVariant(value)}` }
                    />
                  }
                />,
                value
              })) : []
            },
            colProps: {
              lg: 4,
              md: 6
            }
          },
        ].filter(v => v)
      }
    }
  ], [state.schema, usersState.all])

  return (
    <PageApp>
      <Title
        label='Membranes SRNF'
        buttons={ [
          sessionState.user?.role === 'admin' && {
            label: 'Export to CSV',
            size: 'sm',
            href: `${process.env.API_HOST}/membranes-srnf/export?${qs.stringify(state.filters)}`
          },
          {
            label: 'New membrane SRNF',
            size: 'sm',
            onClick: () => {
              actions.setDuplicate()
              actions.setCurrent()
              history.push('/membranes-srnf/new')
            }
          }
        ].filter(v => v) }
      />
      {
        state.schema &&
        <Form
          fields={ filtersFields }
          onSubmit={ (values) => null }
          onFieldChange={ (fieldName, value) => {
            console.log(fieldName, value)
            if (fieldName === 'search') setSearchValue(value)
            else if (fieldName === 'osn' && value) actions.setFilter('source', 'osnDataBase')
            else if (fieldName === 'osn' && !value) actions.setFilter('source', undefined)
            else actions.setFilter(fieldName, value)
          } }
          initialValues={ state.filters }
          noButton
        />
      }
      <div className='mt-3'>
        <Table
          columns={ columns }
          data={ state.all }
          loading={ !request || request.status === 'inProgress' }
          manualPagination={ state.pagination && {
            pageCount: state.pagination.totalPages,
            currentPage: state.pagination.currentPage,
            totalItems: state.pagination.total,
            pageSize: state.pagination.pageSize,
            setPageNumber: (currentPage) => actions.setPagination({ ...state.pagination, currentPage }), 
            setPageSize: (pageSize) => actions.setPagination({ ...state.pagination, pageSize }), 
          } }
          itemsCount={ state.pagination?.total }
          pageCount={ state.pagination?.totalPages }
        />
      </div>
    </PageApp>
  )
}

export default MembranesSrnfList