import * as React from 'react'
import Field, { BaseFormFieldProps } from '../index'
import CS from 'react-select/creatable'
import classNames from 'classnames'

export interface SelectOptions {
  value?: string
  label: string
  [key: string]: any
}

export interface CreatableSelectProps extends BaseFormFieldProps {
  options: SelectOptions[]
  multiple?: boolean
  noCreateLabel?: boolean
}

const CreatableSelect: React.FunctionComponent<CreatableSelectProps> = (props) => {
  const defaultValue = React.useMemo(() => {
    if (!props.defaultValue && props.multiple)
      return []
    else if (!props.defaultValue)
      return {}
    if (Array.isArray(props.defaultValue))
      return props.defaultValue.map((value: string) => 
        props.options.find(o => o.value === value) 
      )
    return props.options.find(o => o.label === props.defaultValue)
  }, [props.defaultValue, props.options])
  
  return(
    <Field { ...props }>
      <CS
        isClearable
        defaultValue={ defaultValue || [] }
        name={ props.name }
        options={ props.options }
        formatCreateLabel={ userInput => props.noCreateLabel ? userInput : `Create "${userInput}"` }
        onChange={ (newValue: any, actionMeta: any) => {
          if (props.onChange && actionMeta.action === 'create-option')
            props.onChange(newValue.value)
          else if (props.onChange)
            props.onChange(newValue)
        } }
        classNamePrefix='c-field-react-select'
        
        /*className= {
          classNames('c-field-react-select', {
            'is-valid': !props.hideValid && !Boolean(props.error),
            'is-invalid': Boolean(props.error)
          })
        }*/
      />
    </Field>
  )
}

export default CreatableSelect