import * as React from 'react'
import Form from '_components/form'
import { useSession } from '_stores/session/store'
import { UpdateProfilePayload } from '_stores/session/types'
import { Button } from 'react-bootstrap'
import RequestMessage from '_components/request-message'

const UserPasswordForm: React.FunctionComponent = () => {
  const [state, actions] = useSession()
  const [formIsVisible, displayPasswordForm] = React.useState(false)
  const requestName = 'put /user/password'
  const request = state.requests && state.requests[requestName]

  React.useEffect(() => {
    if (request && request.status === 'success') {
      request.message = 'Password changed successfully'
      displayPasswordForm(false)
    }
  }, [request])

  if (!formIsVisible)
    return (
      <>
        <p>
          <RequestMessage request={ request } check />
        </p>
        <Button onClick={ () => displayPasswordForm(true) }>
          Change your password
        </Button>
      </>
    )
  
  return (
    <>
      <Form
        fields={ [
          {
            name: 'oldPassword',
            type: 'password',
            placeholder: 'Actual password...',
            autoComplete: 'new-password'
          },
          {
            name: 'password',
            type: 'password',
            placeholder: 'Password...',
            autoComplete: 'new-password'
          },
          {
            name: 'passwordVerification',
            type: 'password',
            placeholder: 'Password verification...',
            autoComplete: 'new-password'
          }
        ] }
        validate={ (values: any) => {
          const errors: any = {}
          if (!values.password)
            errors.password = 'Required field'
          else if (values.password.length < 6)
            errors.password = 'Minimum 6 characters required'
          if (!values.passwordVerification)
            errors.passwordVerification = 'Required field'
          else if (values.passwordVerification !== values.password)
            errors.passwordVerification = 'Incorrect verification password'
          return errors
        } }
        onSubmit={ (values: UpdateProfilePayload) => actions.updateProfile({
          password: values.password,
          oldPassword: values.oldPassword
        }, requestName) }
        request={ request }
        submitLabel='Change your password'
        cancelButton={ {
          onClick: () => displayPasswordForm(false)
        } }
      />
    </>
  ) }

export default UserPasswordForm