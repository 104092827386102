import * as React from 'react'
import { Formik, FormikProps, Field, Form as FormikForm } from 'formik'
import { FormFieldProp } from './types'
import RequestMessage from '_components/request-message'
import { ApiRequest } from '_core/api'
import { getComponent } from './utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faPen } from '@fortawesome/free-solid-svg-icons'

interface AutoSaveFieldProps {
  field: FormFieldProp
  onSubmit(values: any): any
  request?: ApiRequest
  initialValue?: any
  label?: string | React.ReactNode
  forceEditableMode?: boolean
  disableAutoFocus?: boolean
  disabled?: boolean
}

interface LabelProps {
  label: string | React.ReactNode
}

const Label: React.FunctionComponent<LabelProps> = ({
  label
}) =>
  <strong className='label'>{ label }</strong>

const AutoSaveField: React.FunctionComponent<AutoSaveFieldProps> = ({
  field,
  onSubmit,
  request,
  initialValue,
  label,
  forceEditableMode,
  disableAutoFocus,
  disabled
}) => {
  const [isEditable, setEditable] = React.useState(Boolean(forceEditableMode))
  if (!isEditable) {
    return (
      <>
        { label && <Label label={ label } className='label'/> }
        <span
          className='auto-save-form-non-editable'
          onClick={ () => !disabled && setEditable(true) }
        >
          { initialValue }
          {
            !disabled && 
            <FontAwesomeIcon icon={ faPen } className='edit-icon'/>
          }
        </span>
      </>
    )
  }

  return (<>
    { label && <Label label={ label }/> }
    <div className='auto-save-form'>
      <Formik
        enableReinitialize
        initialValues={ { [field.name]: initialValue } }
        onSubmit={ (values) => {
          !forceEditableMode && setEditable(false)
          if (values[field.name] !== initialValue)
            onSubmit(values[field.name])
        } }
      >
        {({
          values = {},
          errors = {},
          touched = {},
          submitCount,
          setFieldValue,
          submitForm
        }: FormikProps<any>) => (
          <FormikForm>
            <RequestMessage
              request={ request }
              loader
            />
            <Field
              key={ field.name }
              { ...field }
              component={ getComponent(field.type) }
              placeholder={ field.placeholder }
              disabled={ disabled }
              submitCount={ submitCount }
              error={ errors[field.name] }
              defaultValue={ initialValue || values[field.name] }
              onChange={ (value: any) => {
                if (field.type === 'checkbox' || field.type === 'select' || field.type === 'react-select') {
                  setFieldValue(field.name, value)
                  submitForm()
                }
              } }
              onBlur={ (value: any) => {
                setFieldValue(field.name, value)
                submitForm()
              } }
              onEnterPress={ (value: any) => {
                setFieldValue(field.name, value)
                submitForm()
              } }
              touched={ touched[field.name] }
              hideValid
              autoFocus={ Boolean(!disableAutoFocus) }
            />
            {
              !forceEditableMode &&
              <span className='auto-save-form__actions'>
                <FontAwesomeIcon
                  onClick={ () => setEditable(false) }
                  icon={ faBan }
                />
              </span>
            }
          </FormikForm>
        )}
      </Formik>
    </div></>
  )

}

export default AutoSaveField