import * as React from 'react'
import { MenuItem } from 'react-pro-sidebar'
import { Link } from 'react-router-dom'

interface SidebarLinkProps {
  to?: string
  href?: string
  label: string | React.ReactNode
  setToggled(toggled: boolean): void
  icon: React.ReactNode
  onClick?(): void
  className?: string
}

const SidebarLink: React.FunctionComponent<SidebarLinkProps> = ({
  to,
  label,
  setToggled,
  icon,
  onClick,
  className,
  href
}) =>
  <MenuItem
    icon={ icon }
    className={ className }
  >
    {
      to ? 
        <Link
          to={ to } 
          onClick={ () => {
            onClick && onClick()
            setToggled(false)
          } }
        >
          { label }
        </Link>
        :
        <a  
          onClick={ () => {
            onClick && onClick()
            setToggled(false)
          } }
          href={ href }
          target={ href ? '_blank' : undefined }
        >
          { label }
        </a>
    }
  </MenuItem>

export default SidebarLink