import { CurrentMembraneSrnfFormValue, MakeSectionFields } from '_containers/membranes-srnf/form'
import { Structure } from '_stores/membranes-srnf/types'
import { hasStructure } from '_stores/membranes-srnf/utils'
import { haveOtherValue } from '_utils/membrane-form/common'

const buildMembraneTypesSectionFields = (
  makeSectionFields: MakeSectionFields,
  currentFormValue: CurrentMembraneSrnfFormValue
): ReturnType<MakeSectionFields> => {
  const types = currentFormValue.family === 'Commercial' ? [] : ['type', 'structure', 'chemistry']

  if (haveOtherValue(currentFormValue.structure))
    types.splice(types.indexOf('structure') + 1, 0, 'structureOther')
  if (haveOtherValue(currentFormValue.chemistry))
    types.splice(types.indexOf('chemistry') + 1, 0, 'chemistryOther')
  if (haveOtherValue(currentFormValue.modification))
    types.splice(types.indexOf('modification') + 1, 0, 'modificationOther')

  if (currentFormValue.family === 'Composite' && currentFormValue.structure && !hasStructure(currentFormValue.structure, Structure.inorganicComposite))
    types.push('topLayerDepositionMethod')

  if (currentFormValue.family === 'Commercial') {
    types.unshift('commercialName')
    if (currentFormValue.commercialName === 'Other') {
      types.push('commercialNameOther')
      types.push('commercialNameOtherManufacturer')
    }
  }
  
  return makeSectionFields(
    '',
    { md: 12 },
    types.map(v => ({ path: v, colProps: { md: 6 } }))
  )
}
export default buildMembraneTypesSectionFields