import * as React from 'react'
import Field, { BaseFormFieldProps } from '../index'
import { Form } from 'react-bootstrap'
import { sortSelectOptions } from '_utils/array'

export interface SelectOptions {
  value?: string
  label: string
  [key: string]: any
}


export interface SelectFieldProps extends BaseFormFieldProps {
  options: SelectOptions[]
  multiple?: boolean
}

export const SelectField: React.FunctionComponent<SelectFieldProps> = (props) => {
  const options = React.useMemo(() => {
    if (props.placeholder)
      return [{ value: '__none', label: props.placeholder }, ...sortSelectOptions(props.options)]
    return sortSelectOptions(props.options)
  }, [props.placeholder, props.options])
  
  const defaultValue = React.useMemo(() => {
    let defaultValue = props.defaultValue
    if (!props.multiple && defaultValue && Array.isArray(defaultValue))
      defaultValue = defaultValue[0]
    return defaultValue
  }, [props.defaultValue])
  return (
    <Field { ...props }>
      <Form.Control
        placeholder={ props.placeholder }
        //isValid={ !props.hideValid && !Boolean(props.error) }
        //isInvalid={ Boolean(props.error) }
        onChange={ (e) => props.onChange &&
          props.onChange(e.target.value !== '__none' ? e.target.value : undefined)
        }
        onBlur={ (e: any) => props.onBlur &&
          props.onBlur(e.target.value !== '__none' ? e.target.value : undefined) }
        as='select'
        multiple={ props.multiple }
        defaultValue={ defaultValue }
      >
        {
          options.map((option, index: number) =>
            <option 
              key={ option.value || index }
              value={ option.value }
            >
              { option.label }
            </option>
          )
        }
      </Form.Control>
    </Field>
  )
}


export default SelectField